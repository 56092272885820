import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: '', loadChildren: './pages/homepage/homepage.module#HomepagePageModule'},
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'chat-bot', loadChildren: './chat-bot/chat-bot.module#ChatBotPageModule' },
  { path: 'product-details/:p_name/:p_id', loadChildren: './product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'product-details/:p_name/:p_id/option/:o_id', loadChildren: './product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'product-details/:c_name/:c_id/:p_name/:p_id', loadChildren: './product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'product-details/:c_name/:c_id/:s_c_name/:s_c_id/:p_name/:p_id', loadChildren: './product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'shop/:type/:c_name/:c_id', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop/:type/:c_name/:c_id/:s_c_name/:s_c_id', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'shop-subcategories/:c_name/:c_id', loadChildren: './shop/shop-subcategories/shop-subcategories.module#ShopSubcategoriesPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'image-modal', loadChildren: './image-modal/image-modal.module#ImageModalPageModule' },
  { path: 'sidemenu', loadChildren: './sidemenu/sidemenu.module#SidemenuPageModule' },
  { path: 'user-addresses', loadChildren: './user-addresses/user-addresses.module#UserAddressesPageModule' },
  { path: 'new-address', loadChildren: './new-address/new-address.module#NewAddressPageModule' },
  { path: 'user-cart', loadChildren: './user-cart/user-cart.module#UserCartPageModule' },
  { path: 'order-summary', loadChildren: './order-summary/order-summary.module#OrderSummaryPageModule' },
  { path: 'order-summary/:gateway', loadChildren: './order-summary/order-summary.module#OrderSummaryPageModule' },
  { path: 'select-address', loadChildren: './select-address/select-address.module#SelectAddressPageModule' },
  { path: 'user-order-details/:o_id', loadChildren: './user-order-details/user-order-details.module#UserOrderDetailsPageModule' },
  { path: 'user-order-history', loadChildren: './user-order-history/user-order-history.module#UserOrderHistoryPageModule' },
  { path: 'order-payment', loadChildren: './order-payment/order-payment.module#OrderPaymentPageModule' },
  { path: 'terms-conditions', loadChildren: './terms-conditions/terms-conditions.module#TermsConditionsPageModule' },
  { path: 'terms-privacy', loadChildren: './terms-privacy/terms-privacy.module#TermsPrivacyPageModule' },
  { path: 'pricelist-modal', loadChildren: './pricelist-modal/pricelist-modal.module#PricelistModalPageModule' },
  { path: 'buynow-pricelist-modal', loadChildren: './buynow-pricelist-modal/buynow-pricelist-modal.module#BuynowPricelistModalPageModule' },
  { path: 'auto-confirm-payment', loadChildren: './auto-confirm-payment/auto-confirm-payment.module#AutoConfirmPaymentPageModule' },
  { path: 'all-offers', loadChildren: './all-offers/all-offers.module#AllOffersPageModule' },
  { path: 'user-wallet', loadChildren: './user-wallet/user-wallet.module#UserWalletPageModule' },
  { path: 'wallet-transactions-modal', loadChildren: './wallet-transactions-modal/wallet-transactions-modal.module#WalletTransactionsModalPageModule' },
  { path: 'paytm-initiate-txn', loadChildren: './paytm/paytm-initiate-txn/paytm-initiate-txn.module#PaytmInitiateTxnPageModule' },
  { path: 'paytm-callback/:o_id', loadChildren: './paytm/paytm-callback/paytm-callback.module#PaytmCallbackPageModule' },
  { path: 'payment-response', loadChildren: './paytm/payment-response/payment-response.module#PaymentResponsePageModule' },
  { path: 'offer-details', loadChildren: './offer-details/offer-details.module#OfferDetailsPageModule' },
  { path: 'user-feedback', loadChildren: './user-feedback/user-feedback.module#UserFeedbackPageModule' },
  { path: 'user-services', loadChildren: './user-services/user-services/user-services.module#UserServicesPageModule' },
  { path: 'service-response/:s_name/:s_id', loadChildren: './user-services/service-response/service-response.module#ServiceResponsePageModule' },
  { path: 'request-details', loadChildren: './user-services/request-details/request-details.module#RequestDetailsPageModule' },
  { path: 'user-wishlist', loadChildren: './user-wishlist/user-wishlist.module#UserWishlistPageModule' },
  { path: 'shop', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'terms-and-conditions', loadChildren: './pages/term-condition/term-condition.module#TermConditionPageModule' },
  { path: 'privacy-policy', loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'cancellation-policy', loadChildren: './pages/cancelation-policy/cancelation-policy.module#CancelationPolicyPageModule' },
  { path: 'refund-policy', loadChildren: './pages/refund-policy/refund-policy.module#RefundPolicyPageModule' },
  { path: 'shipping-policy', loadChildren: './pages/shipping-policy/shipping-policy.module#ShippingPolicyPageModule' },
  { path: 'promo-popup-modal', loadChildren: './promo-popup-modal/promo-popup-modal.module#PromoPopupModalPageModule' },
  { path: 'promo-modal', loadChildren: './promo-modal/promo-modal.module#PromoModalPageModule' },
  { path: 'rate-product', loadChildren: './rating/rate-product/rate-product.module#RateProductPageModule' },
  { path: 'all-ratings', loadChildren: './rating/all-ratings/all-ratings.module#AllRatingsPageModule' },
  { path: 'rate-order', loadChildren: './rating/rate-order/rate-order.module#RateOrderPageModule' },
  { path: 'homepage', loadChildren: './pages/homepage/homepage.module#HomepagePageModule' },
  { path: 'create-subscription', loadChildren: './user-product-subscription/create-subscription/create-subscription.module#CreateSubscriptionPageModule' },
  { path: 'subscription-summary', loadChildren: './user-product-subscription/subscription-summary/subscription-summary.module#SubscriptionSummaryPageModule' },
  { path: 'user-subscriptions', loadChildren: './user-product-subscription/user-subscriptions/user-subscriptions.module#UserSubscriptionsPageModule' },
  { path: 'shop-filters', loadChildren: './shop-filters/shop-filters.module#ShopFiltersPageModule' },
  { path: 'video-modal', loadChildren: './pages/video-modal/video-modal.module#VideoModalPageModule' },
  { path: 'user-referral', loadChildren: './pages/user-referral/user-referral.module#UserReferralPageModule' },
  { path: 'upi-manual-payment', loadChildren: './pages/upi-manual-payment/upi-manual-payment.module#UpiManualPaymentPageModule' },
  { path: 'region-modal', loadChildren: './pages/region-modal/region-modal.module#RegionModalPageModule' },
  { path: 'membership', loadChildren: './pages/membership/membership.module#MembershipPageModule' },
  { path: 'shop-by-category', loadChildren: './pages/shop-by-category/shop-by-category.module#ShopByCategoryPageModule' },
  { path: 'shop-by-brand', loadChildren: './pages/shop-by-brand/shop-by-brand.module#ShopByBrandPageModule' },
  { path: 'select-language', loadChildren: './pages/select-language/select-language.module#SelectLanguagePageModule' },
  { path: 'coupons-list', loadChildren: './pages/coupons-list/coupons-list.module#CouponsListPageModule' },
  { path: 'vendor-registration', loadChildren: './pages/vendor-registration/vendor-registration.module#VendorRegistrationPageModule' },
  { path: 'resale-order', loadChildren: './pages/resale-order/resale-order.module#ResaleOrderPageModule' },
  { path: 'tagged-items', loadChildren: './pages/tagged-items/tagged-items.module#TaggedItemsPageModule' },
  { path: 'cancelled-reason', loadChildren: './pages/cancelled-reason/cancelled-reason.module#CancelledReasonPageModule' },
  { path: 'custom-payment-option', loadChildren: './pages/custom-payment-option/custom-payment-option.module#CustomPaymentOptionPageModule' },
  { path: 'resale-order', loadChildren: './pages/resale-order/resale-order.module#ResaleOrderPageModule' },
  { path: 'tagged-items', loadChildren: './pages/tagged-items/tagged-items.module#TaggedItemsPageModule' },
  { path: 'custom-page/:p_name/:p_id', loadChildren: './pages/custom-page/custom-page.module#CustomPagePageModule' },
  { path: 'contact', loadChildren: './pages/contact/contact.module#ContactPageModule' },
  { path: 'faq', loadChildren: './pages/faq/faq.module#FaqPageModule' },
  { path: 'stripe', loadChildren: './pages/payment-ui/stripe/stripe.module#StripePageModule' },
  { path: 'paypal', loadChildren: './pages/payment-ui/paypal/paypal.module#PaypalPageModule' },
  { path: 'pick-and-drop', loadChildren: './pick-and-drop/pick-and-drop.module#PickAndDropPageModule' },
  { path: 'pick-drop-map', loadChildren: './pick-and-drop/pick-drop-map/pick-drop-map.module#PickDropMapPageModule' },
  { path: 'pick-drop-details', loadChildren: './pick-and-drop/pick-drop-details/pick-drop-details.module#PickDropDetailsPageModule' },
  { path: 'test-page', loadChildren: './pages/test-page/test-page.module#TestPagePageModule' },
  { path: 'my-account', loadChildren: './pages/my-account/my-account.module#MyAccountPageModule' },
  { path: 'map', loadChildren: './new-address/map/map.module#MapPageModule' },
  { path: 'place-modal', loadChildren: './pages/place-modal/place-modal.module#PlaceModalPageModule' },
  { path: 'price-slabs', loadChildren: './pages/price-slabs/price-slabs.module#PriceSlabsPageModule' },
  { path: 'search-items', loadChildren: './pages/search-items/search-items.module#SearchItemsPageModule' },
  { path: 'appointment-schedules', loadChildren: './appointment-schedules/appointment-schedules.module#AppointmentSchedulesPageModule' },
  { path: 'appointment-bookings', loadChildren: './appointment-bookings/appointment-bookings.module#AppointmentBookingsPageModule' },
  { path: 'vendor-info/:v_name/:v_id', loadChildren: './pages/vendor-info/vendor-info.module#VendorInfoPageModule' },
  { path: 'vendor-invoices', loadChildren: './pages/vendor-invoices/vendor-invoices.module#VendorInvoicesPageModule' },
  { path: 'size-chart-modal', loadChildren: './pages/size-chart-modal/size-chart-modal.module#SizeChartModalPageModule' },
  { path: 'order-successful', loadChildren: './pages/order-successful/order-successful.module#OrderSuccessfulPageModule' },
  { path: 'search-results/:search_text', loadChildren: './pages/search-results/search-results.module#SearchResultsPageModule' },
  { path: 'splash-screen', loadChildren: './pages/splash-screen/splash-screen.module#SplashScreenPageModule' },
  
  
  
  
  { path: 'not-found', loadChildren: './pages/not-found/not-found.module#NotFoundPageModule' },
  { path: '**', loadChildren: './pages/not-found/not-found.module#NotFoundPageModule' },






  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
