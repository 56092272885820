import { ConfigService } from '../config/config.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../multi-countries/multi-countries.service";
export class SharedNewService {
    constructor(configService, multiCountriesService) {
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
    }
    showQtyLeftMsg(product, index = 0) {
        const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        let quantity = 0;
        quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    }
    showPriceListQtyLeftMsg(quantity) {
        const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
        return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
    }
    getPriceBasedOnExhangeRate(price) {
        if (this.multiCountriesService.multiCountriesSettings.settings.autoExchange) {
            const toRate = this.multiCountriesService.rates[this.configService.environment.currencyCode];
            const fromRate = this.multiCountriesService.rates['INR'];
            return ((toRate / fromRate) * price);
        }
        else {
            return price;
        }
    }
}
SharedNewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedNewService_Factory() { return new SharedNewService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.MultiCountriesService)); }, token: SharedNewService, providedIn: "root" });
