<ion-header>
  <ion-toolbar class="toolbar">
    <ion-buttons slot="end" (click)="modalDismiss()">
      <ion-button>
        <ion-icon slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title text-center *ngIf="orderData">{{'ORDER_PAYMENT.select_payment' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <br>
  <div *ngIf="showLoader; else showOrderDetails" class="spinner">
    <ion-spinner color="primary"></ion-spinner>
  </div>
  <ng-template #showOrderDetails>

    <div class="order-summery-wrapper white-container">
      <h3>{{'ORDER_PAYMENT.order_summary' | translate}}</h3>


      <table>
        <tr>
          <td>{{'ORDER_PAYMENT.price' | translate}} ({{getTotalItems()}} items)</td>
          <td>{{orderData[0].totalMrp - orderData[0].defaultGst + ((orderData[0].deliveryGstObj.total || 0) + (orderData[0].deliveryGstObj.extraChargeGst || 0)) | currency: currencyCode:true:'.2-2'}}</td>
        </tr>

        <tr>
          <td>{{'ORDER_PAYMENT.offer_discount' | translate}} </td>
          <td class="color-success">-{{orderData[0].discountOnMrp | currency: currencyCode:true:'.2-2'}}</td>
        </tr>

        <tr *ngIf="orderData[0].couponDiscount !== 0">
          <td>{{'ORDER_PAYMENT.coupon_discount' | translate}}</td>
          <td class="color-success">-{{orderData[0].couponDiscount | currency: currencyCode:true:'.2-2'}}</td>
        </tr>

        <tr *ngIf="orderData[0].membershipDiscount">
          <td>
            {{'ORDER_PAYMENT.membership_discount' | translate}}
          </td>
          <td class="color-success">-{{orderData[0].membershipDiscount | currency: currencyCode:true:'.2-2'}}</td>
        </tr>

        <tr *ngIf="orderData[0].extraChargeOnOrder?.charge">
          <td>{{orderData[0].extraChargeOnOrder.name || 'Extra Charge'}}</td>
          <td>{{orderData[0].extraChargeOnOrder.charge | currency: currencyCode:true:'.2-2'}}</td>
        </tr>
        <tr *ngIf="orderData[0].extraChargeOnPayment?.charge">
          <td>{{orderData[0].extraChargeOnPayment.name || 'Payment Gateway Charge'}}</td>
          <td>{{orderData[0].extraChargeOnPayment.charge | currency: currencyCode:true:'.2-2'}}</td>
        </tr>

        <tr *ngIf="orderData[0].defaultGst !== 0">
          <td>{{taxType}}</td>
          <td>{{orderData[0].defaultGst - ((orderData[0].deliveryGstObj.total || 0) + (orderData[0].deliveryGstObj.extraChargeGst || 0)) | currency: currencyCode:true:'.2-2'}}</td>

        </tr>

        <tr
          *ngIf="orderData[0].hasOwnProperty('storePickupObj') && orderData[0].storePickupObj.hasOwnProperty('charges'); else noStorePickup;">
          <td>{{'ORDER_PAYMENT.store_pickup_charges' | translate}}</td>
          <td>{{orderData[0].storePickupObj.charges | currency: currencyCode:true:'.2-2'}}</td>
        </tr>
        <ng-template #noStorePickup>
          <tr>
            <td>{{'ORDER_PAYMENT.delivery_charges' | translate}}</td>
            <td>
              <ng-container *ngIf="orderData[0].delivery !== 0">
                {{orderData[0].delivery | currency: currencyCode:true:'.2-2'}}
              </ng-container>
              <ng-container *ngIf="orderData[0].delivery === 0">
                <span style="color: var(--ion-color-success);">Free</span>
              </ng-container>
            </td>
          </tr>
        </ng-template>

        

        <ng-container *ngIf="showUnavailablePrice()">
          <tr>
            <td>{{'USER_ORDER_DETAILS.total_unavailable' | translate}}</td>
            <td>-{{orderData[0].unavailablePrice | currency: currencyCode:true:'.2-2'}}</td>
          </tr>
        </ng-container>

      </table>
      <div class="sub-total-wrap">
        <table>
          <tr>
            <td>
              <h3>{{'ORDER_PAYMENT.total_amount' | translate}}</h3> <span
                class="sml">({{'ORDER_PAYMENT.incl_taxes' | translate}})</span>
            </td>
            <td>
              <h3>{{orderData[0].totalAmountToPaid | currency: currencyCode:true:'.2-2'}}</h3>
            </td>
          </tr>
        </table>
      </div>
      <div class="sub-total-wrap">
        <table *ngIf="walletActive && (walletBalance > 0 || cashbackBalance > 0)">
          <tr>
            <td class="wallet-bal">
              <ion-checkbox color="primary" (click)="onClickUseWallet()"
                [disabled]="(walletBalance === 0) && (cashbackBalance > 0) && (orderData[0].totalAmountToPaid < minOrderAmntToUseWallet)">
              </ion-checkbox>
              <span> {{'ORDER_PAYMENT.use_wallet_balance' | translate}}</span><br>
              <span>{{'ORDER_PAYMENT.current_balance' | translate}}:
                {{walletBalance - walletAmount | currency: currencyCode:true:'.2-2'}}</span><br>
              <span *ngIf="cashbackBalance > 0">{{'ORDER_PAYMENT.cashback' | translate}}:
                {{cashbackBalance - cashbackAmount | currency: currencyCode:true:'.2-2'}}</span><br>

              <span></span>
            </td>
            <td>
              <ng-container *ngIf="walletUsed">
                <span
                  class="color-success">-{{walletAmount + cashbackAmount | currency: currencyCode:true:'.2-2'}}</span>
              </ng-container>

            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="orderData[0].totalAmountToPaid < minOrderAmntToUseWallet">
        <ion-text color="danger" style="font-size: small;">
          {{'ORDER_PAYMENT.min_amount_to_use_wallet_msg' | translate:{'amount': minOrderAmntToUseWallet  | currency: currencyCode:true:'.2-2'} }}
        </ion-text>
      </div>
      <table *ngIf="walletActive && (walletBalance > 0 || cashbackBalance > 0)">
        <tr>
          <td>
            <h3>{{'ORDER_PAYMENT.you_pay' | translate}}</h3>
          </td>
          <td>
            <h3>{{getFinalAmount() | currency: currencyCode:true:'.2-2'}}</h3>
          </td>
        </tr>
      </table>


    </div>

    <div class="partial-note" *ngIf="partialPayment.status && (getFinalAmount() > 0)">
      <p>
        <span class="info-icon">
          <ion-icon name="information-circle"></ion-icon>
        </span>
        <span class="f-w-b">{{'AUTO_CONFIRM_PAYMENT.note' | translate}}: </span>
        <span
          class="opacity-07 partial-note-txt">{{'AUTO_CONFIRM_PAYMENT.partial_note' | translate:{'onlinePercent': 100 - codPercent} }}</span>
      </p>
    </div>

    <div class="payment-methods">

      <ng-container *ngIf="getFinalAmount() > 0; else wholePaymentWithWallet;">
        <div class="partial" *ngIf="partialPayment.status">
          <div class="partial-selection">
            <div>
              <ion-button fill="clear" expand="block" color="dark" (click)="setPaymentType('full')">
                <ion-checkbox [checked]="paymentType === 'full'" mode="ios" slot="start"></ion-checkbox>
                <span>{{'AUTO_CONFIRM_PAYMENT.pay_in_full' | translate}}</span>
              </ion-button>
            </div>

            <div>
              <ion-button fill="clear" expand="block" color="dark" (click)="setPaymentType('partial')">
                <ion-checkbox [checked]="paymentType === 'partial'" mode="ios" slot="start"></ion-checkbox>
                <span>{{'AUTO_CONFIRM_PAYMENT.pay_partially' | translate}}</span>
              </ion-button>
            </div>
          </div>

          <div class="partial-info" *ngIf="paymentType === 'partial'">
            <div>
              <p>{{'AUTO_CONFIRM_PAYMENT.pay_with_online' | translate}}</p>
              <p>{{partialPayment.online.amount | currency: currencyCode:true:'.2-2'}}</p>
            </div>
            <div>
              <i class="flaticon-null-28"></i>
            </div>
            <div>
              <p>{{'AUTO_CONFIRM_PAYMENT.pay_with_cod' | translate}}</p>
              <p>{{partialPayment.cod | currency: currencyCode:true:'.2-2'}}</p>
            </div>
          </div>
        </div>
        <ion-list lines="none" class="pay-list">
          <ion-item button (click)="onClickPaymentMode('cash')" detail class="ion-no-padding ion-item"
            *ngIf="isCod && !partialPayment.status">
            <ion-label>
              <h4>{{'SHARED.cash_on_delivery' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.cash"></ng-container>
            </ion-label>
          </ion-item>
          <ion-item button (click)="onClickPaymentMode('paytm')" detail *ngIf="paytmActive && envPaytmActive"
            class="ion-no-padding ion-item">
            <ion-label>
              <h4>{{'SHARED.paytm' | translate}}</h4>
            <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.paytm"></ng-container>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('card')" detail *ngIf="razorpayActive"
            class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/credit-card.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.credit_debit_cards' | translate}}</h4>
            <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('wallet')" detail *ngIf="razorpayActive"
            class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/wallet.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.wallets' | translate}}</h4>
            <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('upi')" detail *ngIf="razorpayActive"
            class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/upi.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.upi' | translate}}</h4>
            <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('netbanking')" detail *ngIf="razorpayActive"
            class="ion-no-padding">
            <ion-img slot="start" src="../../assets/icon/online-banking.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.netbanking' | translate}}</h4>
            <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.razorpay"></ng-container>
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('upiManual')" detail
            *ngIf="upiManual.active && (paymentType !== 'partial')" class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/upi.png"></ion-img>
            <ion-label class="ion-text-wrap">
              {{'SHARED.upi_manual' | translate}}
            </ion-label>
          </ion-item>

          <ion-item button (click)="onClickPaymentMode('custom')" detail
            *ngIf="customOption.active && (paymentType !== 'partial')" class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/cod.png"></ion-img>
            <ion-label class="ion-text-wrap">{{customOption.name}}</ion-label>
          </ion-item>

          <!-- stripe method -->
          <ion-item button (click)="onClickPaymentMode('stripe')" detail *ngIf="stripeData.active"
            class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/credit-card.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.credit_debit_cards' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.stripe"></ng-container>
            </ion-label>
          </ion-item>
          <!-- stripe method -->

          <!-- paypal method -->
          <ion-item button (click)="onClickPaymentMode('paypal')" detail *ngIf="paypalObj.active" class="ion-no-padding ion-item">
            <ion-img slot="start" src="../../assets/icon/paypal.png"></ion-img>
            <ion-label class="ion-text-wrap">
              <h4>{{'SHARED.paypal' | translate}}</h4>
              <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.paypal"></ng-container>
            </ion-label>
          </ion-item>
          <!-- paypal method -->

          <!-- cashfree method -->
          <ion-item button (click)="onClickPaymentMode('cashfree')" detail *ngIf="cashfreeObj.active"
          class="ion-no-padding ion-item">
          <ion-img slot="start" src="../../assets/icon/cashfree.jpg"></ion-img>
          <ion-label class="ion-text-wrap">
            <h4>
              {{'SHARED.cashfree' | translate}}
            </h4>
            <ng-container *ngTemplateOutlet="extraChargeTemplate; context: extraCharge.cashfree"></ng-container>
          </ion-label>
        </ion-item>
        <!-- cashfree method -->

        </ion-list>
      </ng-container>


      <ng-template #wholePaymentWithWallet>
        <div class="wallet-covers-txt">
          {{'AUTO_CONFIRM_PAYMENT.wallet_covers_payment' | translate}}
        </div>
      </ng-template>
    </div>
  </ng-template>
</ion-content>
<ion-footer *ngIf="orderData && getFinalAmount() === 0">
  <ion-button class="btn-1 i-start" expand="full" (click)="completePaymentWithWallet()">
    <i class="flaticon-null-20 app-footer-icon"></i>
    {{'AUTO_CONFIRM_PAYMENT.place_order' | translate}}
  </ion-button>
</ion-footer>



<!-- extra charge template -->
<ng-template #extraChargeTemplate let-charge="charge" let-type="type" let-maxCharge="maxCharge"> 
  <ng-container *ngIf="charge">
    <h5 class="ion-text-wrap extra-charge-txt" *ngIf="type === 'flat'; else percentCharge;">{{'SHARED.extra_charge_flat' | translate:{'amount': charge | currency: currencyCode:true:'.2-2'} }}</h5>
    <ng-template #percentCharge>
      <h5 class="ion-text-wrap extra-charge-txt" *ngIf="maxCharge">{{'SHARED.extra_charge_upto_percent' | translate:{'percent': charge, 'maxCharge': maxCharge | currency: currencyCode:true:'.2-2'} }}</h5>
      <h5 class="ion-text-wrap extra-charge-txt" *ngIf="!maxCharge">{{'SHARED.extra_charge_percent' | translate:{'percent': charge} }}</h5>
    </ng-template>
  </ng-container>
</ng-template>