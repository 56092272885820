export const environment = {
    production: true,
    firebase: {
    apiKey: "AIzaSyCyumRlKcbE8u9Gt00igH1g5W325BdDShs",
    authDomain: "bwi-potlideals.firebaseapp.com",
    projectId: "bwi-potlideals",
    databaseURL: "https://bwi-potlideals.firebaseio.com",
    storageBucket: "bwi-potlideals.appspot.com",
    messagingSenderId: "757748906336",
    appId: "1:757748906336:web:55c68e01665a1c7ba28605",
    measurementId: "G-8G5FCS9KK3",
    },
    shopProductsLimit: 50,
    timeToUpdateAgentLocation: 30000,
    deliveryBikeurl: 'assets/img/delivery-bike.png',
    destinationMarkerUrl: 'assets/img/map-marker.png',
    razorpay: {
      description: "Potlideals Payment",
      currency: 'INR',
      image: 'https://potlideals.estore.business/assets/img/admin-pic.png',
      theme: {
        color: '#f79d10'
      },
      useCheckoutJs: false
    },
    paytm: {
      CHANNEL_ID: 'WAP',
      WEBSITE: 'DEFAULT',
      INDUSTRY_TYPE_ID: 'Retail',
      ENVIRONMENT: 'production'
    },
    envPaytmActive: false,
    storeName: "Potlideals",
    supportPhone: '1234567890',
    scrollLimit:20,
    priceReqFeature:false,
    appSharingImg:'https://potlideals.estore.business/assets/img/admin-pic.png',
    deliveryFeature: false,
    ALGOLIA_APP_ID: 'DEYL6OZL2B',
    ALGOLIA_APP_KEY: 'd7f75c2a6d20c84da946c70a08039dbc',
    isDeliveryBasedKm: false,
    orderIdPrefix: 'ORD',
    defaultCountryCode: '+91',
    currencyCode: 'INR',
    taxType: 'GST',
    socialSigin: false,
    defaultProductView: 'list',
    webClientId:'757748906336-pq6n31t5sdpmhbe9ichjekcch22g3lkc.apps.googleusercontent.com',
    productSharing: true,
    phoneLength: 10,
    showFooterLogo: true,
    openAlgoliaSearch:false,
    LOGGLY_API_KEY: 'dd80a702-e3cf-4633-9d38-5ee92fe68193',
    priceForRetail: false,
    referralFeature: false,
    subscriptionFeature: false,
    RFQFeature: false,
    usersScrollLimit: 20,
    limitedTimeDeal: false,
    productRatings: false,
    multiRegion: false,
    multiVendor: false,
    membership: false,
    isDeliveryBasedOnWeight: false,
    userSignature: false,
    freeTrial: false,
    isBwiClient: false,
    lockSystem: false,
    broadcastLimit:24,
    imagesLimit: 5,
    orderUploadedDocImgsLimit: 5,
    resellingFeature: false,
    shareCatalogue: false,
    qtyLeft: 5,
    allOrderStatuses: ['Pending', 'Rejected', 'Confirmed', 'Cancelled', 'Dispatched', 'Delivered', 'Returned'],
    appAccessOnApproval: false,
    pickDrop: false,
    cashfree: {production: false},
    appointmentFeature: false
  };
