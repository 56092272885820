import * as tslib_1 from "tslib";
import { NgZone } from "@angular/core";
import { Events, AlertController, ToastController, IonRouterOutlet, ModalController, MenuController, LoadingController, } from "@ionic/angular";
import { UserService } from "../services/user/user.service";
import * as firebase from "firebase/app";
import { Storage } from "@ionic/storage";
import { ConfigService } from "../services/config/config.service";
import { LabelService } from "src/app/services/label/label.service";
import { AdminSettingsService } from "../services/admin-settings/admin-settings.service";
import { TranslateService } from "@ngx-translate/core";
import { MultiCountriesService } from "../services/multi-countries/multi-countries.service";
export class HomePage {
    constructor(events, alertController, toastController, modalController, menuController, 
    //private platform: Platform,
    configService, ngZone, loadingController, storage, labelService, userService, translateService, adminSettingsService, multiCountriesService) {
        this.events = events;
        this.alertController = alertController;
        this.toastController = toastController;
        this.modalController = modalController;
        this.menuController = menuController;
        this.configService = configService;
        this.ngZone = ngZone;
        this.loadingController = loadingController;
        this.storage = storage;
        this.labelService = labelService;
        this.userService = userService;
        this.translateService = translateService;
        this.adminSettingsService = adminSettingsService;
        this.multiCountriesService = multiCountriesService;
        this.phoneNo = null;
        this.verificationID = "";
        this.lastTimeBackPress = 0;
        this.timePeriodToExit = 2000;
        this.error = false;
        this.mode = "mobile";
        this.isLoading = false;
        this.showNameInput = false;
        this.userName = "";
        this.userEmail = "";
        this.showPincode = false;
        this.storeData = {
            allowComment: false,
            allowStoreInfo: false,
            appPackageName: "",
            appVersion: "",
            commentMsg: "",
            facebookUrl: "",
            instagramUrl: "",
            storeAddress: {
                address: "",
                city: "",
                state: "",
                stateCode: "",
            },
            storeInfo: "",
            storeName: "storeName",
            storePhone: "",
            twitterUrl: "",
            welcomeMsg: "",
            youtubeUrl: "",
        };
        this.newUser = false;
        this.socialSigin = false;
        this.phoneLength = this.configService.environment.phoneLength;
        this.onlyIdentity = false;
        this.showSignin = false;
        this.notDeliverable = false;
        this.pincode = "";
        this.maxDate = new Date().toISOString();
        this.birthday = "";
        this.HOME_LABELS = {};
        this.SHARED_LABELS = {};
        this.loginPopupSettings = {
            name: true,
            email: true,
            dob: true,
            gst: false,
            custom: {
                active: false,
                name: "",
            },
        };
        this.taxType = "";
        this.userGst = "";
        this.customInput = "";
        this.showResendBtn = false;
        this.showTimer = true;
        this.countResendBtnClicked = 0;
        this.isTooManyOtp = false;
        this.telInputOptions = {
            autoHideDialCode: false,
            initialCountry: "in",
            separateDialCode: true,
            onlyCountries: ["in"],
            preferredCountries: ["in"],
        };
        this.isNumberValid = false;
        this.defaultCountryCode = "";
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setCountriesOptions();
            const deliverySettings = yield this.storage.get("deliverySettings");
            if (deliverySettings) {
                const isAllowAllPincodes = deliverySettings.isAllowAllPincodes;
                const checkPincode = yield this.storage.get("checkPincode");
                const region = yield this.storage.get("region");
                if ((!checkPincode || checkPincode === undefined) &&
                    !isAllowAllPincodes &&
                    (!region || region === undefined)) {
                    this.showPincode = true;
                }
                else {
                    this.showSignin = true;
                }
            }
            this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
                size: "invisible",
                badge: "bottomleft",
                callback: function (response) {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // onSignInSubmit();
                },
            });
            this.recaptchaVerifier.render();
        });
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.socialSigin = this.configService.environment.socialSigin;
            this.initializeSubscriptions();
            this.SHARED_LABELS = this.labelService.labels["SHARED"];
            this.HOME_LABELS = this.labelService.labels["HOME"];
            this.taxType = this.configService.environment.taxType || "GST";
            const storeSettings = yield this.adminSettingsService.getSettingsData();
            if (storeSettings) {
                this.loginPopupSettings =
                    storeSettings &&
                        storeSettings.loginPopup &&
                        Object.keys(storeSettings.loginPopup).length
                        ? storeSettings.loginPopup
                        : this.loginPopupSettings;
            }
            this.defaultCountryCode = this.configService.environment.defaultCountryCode;
        });
    }
    ionViewWillLeave() {
        this.removeSubscriptions();
    }
    initializeSubscriptions() {
        this.storage.get("storeInfo").then((result) => {
            this.storeData = result;
        });
        this.events.subscribe("user:userAlreadyExists", () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = false;
            yield this.multiCountriesService.setDefaultCountry();
            this.modalDismiss();
            window.location.reload();
            this.menuController.close();
        }));
        this.events.subscribe("user:userCreatedSuccessfully", (uid, name, email) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = false;
            this.uid = uid;
            this.storage.set("uid", uid);
            this.userName = name === "user" ? "" : name;
            this.userEmail = email;
            this.showNameInput = true;
            yield this.multiCountriesService.setDefaultCountry();
        }));
        this.events.subscribe("user:updateNameOfNewUserSuccess", () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = false;
            this.modalDismiss();
            window.location.reload();
            this.menuController.close();
        }));
        this.events.subscribe("auth:phoneAlreadyInUse", (mode) => {
            if (mode === "otp" || !mode) {
                this.isLoading = true;
                this.loadingTxt = this.HOME_LABELS["sending_otp"];
                this.sendOtp();
            }
            if (mode === "facebook") {
                this.isLoading = true;
                this.loadingTxt = this.HOME_LABELS["signing_in"];
                this.events.publish("auth:fbLogin", this.defaultCountryCode + this.phoneNo);
            }
            if (mode === "google") {
                this.isLoading = true;
                this.loadingTxt = this.HOME_LABELS["signing_in"];
                this.events.publish("auth:googleLogin", this.defaultCountryCode + this.phoneNo);
            }
        });
        this.events.subscribe("auth:newUser", () => {
            if (this.socialSigin) {
                this.isLoading = true;
                this.loadingTxt = this.HOME_LABELS["submit"];
                this.newUser = true;
            }
            else {
                this.sendOtp();
            }
        });
        this.events.subscribe("delivery-settings:pincodeDeliverable", () => {
            //this.btnTxt = this.HOME_LABELS['submit'];
            this.showPincode = false;
            this.showSignin = true;
            this.storage.set("checkPincode", true);
        });
        this.events.subscribe("delivery-settings:pincodeNotDeliverable", () => {
            //this.btnTxt = this.HOME_LABELS['submit'];
            this.notDeliverable = true;
        });
    }
    checkPincode() {
        this.events.publish("delivery-settings:checkPincode", this.pincode);
    }
    skipPincodeCheck() {
        //this.btnTxt = this.HOME_LABELS['submit'];
        this.showPincode = false;
        this.showSignin = true;
        this.notDeliverable = false;
    }
    pincodeChange() {
        this.notDeliverable = false;
    }
    signIn() {
        // if(!this.isNumberValid) {
        //     this.presentAlert('Please enter a valid phone number');
        //     return;
        // }
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS["verifying"];
        console.log("this.defaultCountryCode + this.phoneNo", this.defaultCountryCode + this.phoneNo);
        this.events.publish("auth:checkUserStatus", this.defaultCountryCode + this.phoneNo);
    }
    reSendOtp() {
        this.showResendBtn = false;
        this.sendOtp();
    }
    resendCodeTimer() {
        this.timer = 60;
        const interval = setInterval(() => {
            this.timer -= 1;
            if (this.timer === 0) {
                clearInterval(interval);
                this.showTimer = false;
                this.showResendBtn = true;
            }
        }, 1000);
    }
    sendOtp() {
        this.resendCodeTimer();
        this.newUser = false;
        this.isLoading = true;
        const appVerifier = this.recaptchaVerifier;
        const phoneNumberString = this.defaultCountryCode + this.phoneNo;
        console.log("phoneNumberString", phoneNumberString);
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumberString, appVerifier)
            .then((result) => {
            this.ngZone.run(() => {
                // console.log(result);
                this.confirmationResult = result;
                this.mode = "otp";
                this.isLoading = false;
            });
        })
            .catch((error) => {
            this.ngZone.run(() => {
                this.error = true;
                this.isLoading = false;
                if (error ===
                    "We have blocked all requests from this device due to unusual activity. Try again later.") {
                    this.isTooManyOtp = true;
                    this.errorMsg =
                        "Invalid Phone Number. Please check your phone number and try again.";
                }
                this.errorMsg = "Too many attempts. try again later";
            });
        });
    }
    verifyLoginCode() {
        this.isLoading = true;
        this.loadingTxt = this.HOME_LABELS["verifying"];
        this.confirmationResult
            .confirm(this.verificationCode)
            .then((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.user = result.user;
            this.uid = this.user.uid;
            this.userService.checkUserAlreadyExistsOrNot(result.user.uid);
        }))
            .catch((error) => {
            this.error = true;
            this.errorMsg = "Incorrect OTP entered";
            this.isLoading = false;
            this.loadingTxt = this.HOME_LABELS["login"];
        });
    }
    checkError() {
        this.error = false;
    }
    googleLogin() {
        this.newUser = false;
        this.events.publish("auth:googleLogin", this.defaultCountryCode + this.phoneNo);
    }
    fbLogin() {
        this.newUser = false;
        this.events.publish("auth:fbLogin", this.defaultCountryCode + this.phoneNo);
    }
    updateUser() {
        //// console.log('username:', this.userName);
        const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.userEmail && !this.userEmail.match(mailFormat)) {
            this.presentAlert(this.translateService.instant("HOME.enter_valid_email"));
            return;
        }
        if (this.userName) {
            this.isLoading = true;
            this.loadingTxt = this.HOME_LABELS["saving"];
            this.events.publish("user:updateNameOfNewUser", {
                name: this.userName,
                email: this.userEmail,
                birthday: this.birthday,
                lowercaseName: this.userName.toLowerCase(),
                customerGstNo: this.userGst,
                customInput: this.customInput,
            }, this.uid);
        }
        else {
            this.presentAlert("Please Enter Name");
        }
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                color: "medium",
                message: msg,
                duration: 2000,
                showCloseButton: true,
                cssClass: "toast",
                animated: true,
            });
            toast.present();
        });
    }
    presentAlert(desc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: desc,
                buttons: [
                    {
                        text: "Ok",
                        handler: () => {
                            //// console.log('Confirm Okay');
                        },
                    },
                ],
            });
            yield alert.present();
        });
    }
    presentLoading(msg, drtn) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: msg,
                duration: drtn,
            });
            yield this.loading.present();
        });
    }
    modalDismiss() {
        this.modalController.dismiss();
        // document.querySelector(".scroll").animate(
        //   [
        //     // keyframes
        //     { transform: "translateX(0)" },
        //     { transform: "translateX(100%)" },
        //   ],
        //   {
        //     // timing options
        //     duration: 100,
        //     // iterations: Infinity
        //   }
        // );
        // setTimeout(() => {
        // }, 0);
    }
    setCountriesOptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const countries = [];
            this.multiCountriesService.multiCountriesSettings.countries.forEach((country) => {
                countries.push(country.countryCode);
            });
            console.log("countries", countries);
            this.telInputOptions.onlyCountries = countries;
            // this.telInputOptions.initialCountry = this.multiCountriesService.multiCountriesSettings.settings.defaultCountry.countryCode;
            this.telInputOptions.initialCountry = (yield this.multiCountriesService.getDefaultCountry()).countryCode;
        });
    }
    onCountryChange(e) {
        console.log(e);
        this.defaultCountryCode = `+${e.dialCode}`;
        this.multiCountriesService.countryInLogin =
            this.multiCountriesService.multiCountriesSettings.countries.find((c) => c.dialCode === this.defaultCountryCode);
    }
    getNumber(e) {
        console.log(e);
    }
    telInputObject(e) {
        console.log(e);
    }
    hasError(e) {
        console.log(e);
        this.isNumberValid = e;
    }
    phoneChange() {
        this.isTooManyOtp = false;
        this.newUser = false;
        this.isNumberValid = false;
    }
    removeSubscriptions() {
        this.events.unsubscribe("user:userAlreadyExist");
        this.events.unsubscribe("user:userCreatedSuccessfully");
        this.events.unsubscribe("user:updateNameOfNewUserSuccess");
        this.events.unsubscribe("auth:phoneAlreadyInUse");
        this.events.unsubscribe("auth:newUser");
        this.events.unsubscribe("delivery-settings:pincodeDeliverable");
        this.events.unsubscribe("delivery-settings:pincodeNotDeliverable");
    }
}
