import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { Product } from 'src/app/models/product';
import { Observable } from 'rxjs';
import { map, first, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { firestore } from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage/';
import { ProductImage } from 'src/app/models/image';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase';
import { ConfigService } from '../config/config.service';
import { Storage } from '@ionic/storage';
import algoliasearch from 'algoliasearch';
import { SharedService } from '../shared/shared.service';
import { convertSnaps } from '../db-utils';
@Injectable({
    providedIn: 'root'
})
export class ProductService {
    client: any;
    index: any;
    ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
    ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
    APP_PROJECT_ID = environment.firebase.projectId
    products: Observable<Product[]>;
    uproducts: Observable<Product[]>;
    itemDoc: AngularFirestoreDocument<Product>;
    productsRef: AngularFirestoreCollection<Product>;
    docRef: AngularFirestoreCollection<unknown>;
    mediaRef: AngularFirestoreCollection<unknown>;
    doc: any;
    productsLength: number;
    lopRef: AngularFirestoreDocument<unknown>;
    doc$: Observable<unknown>;
    udocRef: AngularFirestoreCollection<unknown>;
    uDoc: any;
    getproductsRef: AngularFirestoreCollection<Product>;
    getproductsDoc: Observable<Product[]>;
    productRef: AngularFirestoreCollection<unknown>;
    updateStatusRef: AngularFirestoreCollection<unknown>;
    updateStatusDoc: any;
    imageId: any;
    image: ProductImage = {
        url: null,
        size: null,
        uploadedAt: null,
        productId: null
    };
    userRef: AngularFirestoreCollection<unknown>;
    lastInResponse: any;
    productsData: any[] = [];
    categoryData: any;
    // countOfProducts: number;
    productSub: Subscription;
    lastResponseForAdminProducts: any;
    productsDataForAdminProducts: any[] = [];
    firstResponseForAdminProducts: any;
    // lastResponseOfProducts: any;
    // productsWithoutCategoryData: any[] = [];
    constructor(private afs: AngularFirestore, private events: Events,
        private storage: Storage,
        private configService: ConfigService,
        private fbStorage: AngularFireStorage,
        private sharedService: SharedService, ) { }
    initializeSubscriptions() {
        this.events.subscribe('product:addProduct', (product, listofImages, barcode) => {
            this.addProduct(product, listofImages, barcode);
        });
        this.events.subscribe('product:getProducts', (id, type) => {
            this.getProducts(id, type);
        });
        this.events.subscribe('product:getProductWithId', (id) => {
            this.getProductWithId(id);
        });
        this.events.subscribe('product:updateStatus', (id) => {
            this.updateStatus(id);
        });
        this.events.subscribe('product:editProduct', (editdata, pid, listOfImages, barcode) => {
            this.editProduct(editdata, pid, listOfImages, barcode);
        });
        this.events.subscribe('product:deleteProduct', (id) => {
            this.deleteProduct(id);
        });
        this.events.subscribe('product:loadMoreProducts', (id, type) => {
            this.loadMoreProducts(id, type);
        });
        this.events.subscribe('product:addCategory', (catgeory, categoryImage, status, banner) => {
            this.addCategory(catgeory, categoryImage, status, banner);
        });
        this.events.subscribe('product:getAllCategories', () => {
            this.getAllCategories();
        });
        this.events.subscribe('product:getAllCategoriesForShop', () => {
            this.getAllCategoriesForShop();
        });
        this.events.subscribe('product:deleteCategory', (catgeoryId) => {
            this.deleteCategory(catgeoryId);
        });
        this.events.subscribe('product:editCategory', (categoryData, categoryImage, status, banner) => {
            this.editCategory(categoryData, categoryImage, status, banner);
        });
        this.events.subscribe('product:getProductsForCategory', (cid) => {
            this.getProductsForCategory(cid);
        });
        this.events.subscribe('product:getProductsForCategory', (cid) => {
            this.getProductsForCategory(cid);
        });
        this.events.subscribe('product:getProductsForAdminProducts', () => {
            this.getProductsForAdminProducts();
        });
        this.events.subscribe('product:loadMoreProductsForAdminProducts', () => {
            this.loadMoreProductsForAdminProducts();
        });
        this.events.subscribe('product:loadPreviousProductsForAdminProducts', () => {
            this.loadPreviousProductsForAdminProducts();
        });
        this.events.subscribe('product:getSubcategories', (id) => {
            this.getSubcategories(id);
        });
        this.events.subscribe('product:getSubcategoriesForUser', (id) => {
            this.getSubcategoriesForUser(id);
        });
        this.events.subscribe('product:addSubcategory', (data, image, categoryId, banner) => {
            this.addSubcategory(data, image, categoryId, banner);
        });
        this.events.subscribe('product:editSubcategory', (data, image, subcatId, categoryId, banner) => {
            this.editSubcategory(data, image, subcatId, categoryId, banner);
        });
        this.events.subscribe('product:deleteSubcategory', (subcatId, categoryId) => {
            this.deleteSubcategory(subcatId, categoryId);
        });
        this.events.subscribe('product:getProductsForSubcategory', (subcatId) => {
            this.getProductsForSubcategory(subcatId);
        });
        this.events.subscribe('product:changeSubcategoriesStatus', (status, catId) => {
            this.changeSubcategoriesStatus(status, catId);
        });
        this.events.subscribe('product:getAnalyticsProductsCount', () => {
            this.getAnalyticsProductsCount();
        });
        this.events.subscribe('product:makeProductCopies', (copies, product) => {
            this.makeProductCopies(copies, product);
        });
        this.events.subscribe('product:getCategoriesData', (cid) => {
            this.getCategoriesData(cid);
        });

        this.events.subscribe('product:getSubCategoriesData', (cid, scid) => {
            this.getSubCategoriesData(cid, scid);
        });

        this.setCategoriesInLocalStorage();


        this.events.subscribe('product:removeSusbcriptions', () => {
            if (this.productSub) {
               
                this.productSub.unsubscribe();
            }
        });


        this.lopRef = this.afs.doc(`listofProducts/list`);
        this.mediaRef = this.afs.collection('media');
        this.productRef = this.afs.collection('products', ref => ref.orderBy('sortedAt', 'desc'));
        this.userRef = this.afs.collection('users');
    }

    async addProduct(product: Product, listofImages, barcode) {
        try {
            let colorObj: any = {};
            let priceList = [];
            if (barcode === '') {
                product['barcode'] = '';
            }
            if (product.color.hasOwnProperty('image') && product.color.image !== '' && product.color.image.includes('data:image/jpeg;base64,')) {
                colorObj = { ...product.color };
                delete product.color.image;
            }
            if (product.isPriceList) {
                priceList = JSON.parse(JSON.stringify(product.priceList));
                for (const pl of product.priceList) {
                    if (pl.hasOwnProperty('barcode')) {
                        delete pl.barcode;
                    }
                }
            }


            const doc_ref = await this.afs.collection('products').add(product);
            if (listofImages.length !== 0) {
                await this.addimgtoFirebase(doc_ref.id, listofImages);
            } else {
                await this.afs.collection('products').doc(doc_ref.id).update({
                    coverPic: {
                        url: 'assets/img/placeholder-img.jpg',
                        mob: 'assets/img/placeholder-img.jpg',
                        thumb: 'assets/img/placeholder-img.jpg'
                    }
                });
            }
            if (barcode !== '') {
                const imgRef: any = this.fbStorage.ref(`products/${doc_ref.id}/barcode/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(barcode, 'data_url');
                const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                await this.afs.collection('products').doc(doc_ref.id).update({
                    barcode: downloadURL
                });
            }
            if (colorObj.hasOwnProperty('image')) {
                const imgRef: any = this.fbStorage.ref(`products/${doc_ref.id}/color/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(colorObj.image, 'data_url');
                const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                await this.afs.collection('products').doc(doc_ref.id).update({
                    color: {
                        image: downloadURL,
                        name: colorObj.name,
                        code: colorObj.code
                    }
                });
            }
            if (priceList.length) {
                for (const pl of priceList) {
                    if (pl.hasOwnProperty('barcode') && pl.barcode !== '') {
                        const imgRef: any = this.fbStorage.ref(`products/${doc_ref.id}/barcode/` + new Date().getTime().toString() + '.png');
                        await imgRef.putString(pl.barcode, 'data_url');
                        const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                        pl.barcode = downloadURL;
                    }
                }
                await this.afs.collection('products').doc(doc_ref.id).update({
                    priceList: priceList
                });
            }
            this.events.publish('product:addSuccess', 'Success', 'Product added successfully. The images will be uploaded in just couple of minutes.');
        } catch (error) {
          
            this.events.publish('product:addFailure', 'Failure', 'Product not added successfully');
        }
    }

    async editProduct(editdata, pid, listOfImages, barcode) {
      
        let colorObj: any = {};
        let priceList = [];
        if (editdata.color.hasOwnProperty('image') && editdata.color.image.includes('data:image/jpeg;base64,')) {
            colorObj = { ...editdata.color };
            delete editdata.color.image;
        }

        if (editdata.isPriceList) {
            priceList = JSON.parse(JSON.stringify(editdata.priceList));
            for (const pl of editdata.priceList) {
                if (pl.hasOwnProperty('barcode') && pl.barcode.includes('data:image/jpeg;base64,')) {
                    delete pl.barcode;
                }
            }
        }

        if (barcode !== '') {
            const imgRef: any = this.fbStorage.ref(`products/${pid}/barcode/` + new Date().getTime().toString() + '.png');
            await imgRef.putString(barcode, 'data_url');
            const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
            editdata["barcode"] = downloadURL;
            await this.afs.collection('products').doc(pid).update(editdata);
        } else {
            await this.afs.collection('products').doc(pid).update(editdata);
        }
        if (colorObj.hasOwnProperty('image')) {
           
            const imgRef: any = this.fbStorage.ref(`products/${pid}/color/` + new Date().getTime().toString() + '.png');
            await imgRef.putString(colorObj.image, 'data_url');
            const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
            await this.afs.collection('products').doc(pid).update({
                color: {
                    image: downloadURL,
                    name: colorObj.name,
                    code: colorObj.code
                }
            });
        }
        if (priceList.length) {
            for (const pl of priceList) {
                if (pl.hasOwnProperty('barcode') && pl.barcode.includes('data:image/jpeg;base64,')) {
                    const imgRef: any = this.fbStorage.ref(`products/${pid}/barcode/` + new Date().getTime().toString() + '.png');
                    await imgRef.putString(pl.barcode, 'data_url');
                    const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                    pl.barcode = downloadURL;
                }
            }
            await this.afs.collection('products').doc(pid).update({
                priceList: priceList
            });
        }
        if (listOfImages.length !== 0) {
            try {
                await this.addimgtoFirebase(pid, listOfImages);
                this.events.publish('product:editSuccess', 'Success', 'Product edited successfully!');
            } catch (error) {
             
                this.events.publish('product:editFailure', 'Failure', 'Product not edited successfully!');
            }
        } else if (!editdata.images.length && !listOfImages.length) {
            await this.afs.collection('products').doc(pid).update({
                coverPic: {
                    url: 'assets/img/placeholder-img.jpg',
                    mob: 'assets/img/placeholder-img.jpg',
                    thumb: 'assets/img/placeholder-img.jpg'
                }
            });
            this.events.publish('product:editSuccess', 'Success', 'Product edited successfully!');
        }
        else {
           
            this.events.publish('product:editSuccess', 'Success', 'Product edited successfully!');
        }
    }
    async addimgtoFirebase(pid, imgdataAndSize) {
        for (let i = 0; i < imgdataAndSize.length; i++) {
            this.image.url = '';
            this.image.size = imgdataAndSize[i].size;
            this.image.uploadedAt = new Date();
            this.image.productId = pid;
            const mediaDocRef = await this.mediaRef.doc('images').collection('products').add(this.image);
            const imgRef: any = this.fbStorage.ref(`products/${pid}/images/` + mediaDocRef.id + '.png');
            await imgRef.putString(imgdataAndSize[i].base64Img, 'data_url');
            if (imgdataAndSize[i].cover === true) {
                const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                this.afs.collection('products').doc(pid).update({ coverPic: { imageId: mediaDocRef.id, url: downloadURL } });
            }
        }
    }

    async getProducts(id: string, type: string) {
        this.productsData = [];
        let productRef: AngularFirestoreCollection;
        productRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit));
        
        if(type === 'vendor') {
            productRef = this.afs.collection('products', ref => ref
                .where('vendorId', '==', id)
                .where('status', '==', true)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit));
        } else {
            const region = await this.sharedService.checkRegionIdForApi();
            if(region.vendorId && region.vendorId !== '') {
                productRef = this.afs.collection('products', ref => ref
                    .where(`${type}`, 'array-contains', id)
                    .where('status', '==', true)
                    .where('vendorId', '==', region.vendorId)
                    .orderBy('sortedAt', 'desc')
                    .limit(this.configService.environment.shopProductsLimit));
            }
        }

        this.productSub = productRef.snapshotChanges()
            .subscribe((response: any) => {
                if (!response.length) {
                    
                    this.events.publish('product:noProductAvailable');
                    return false;
                }
                this.productsData = [];
                this.lastInResponse = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsData.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
         
                if (this.productsData.length !== 0) {
                   
                    this.events.publish('product:publishProducts', this.productsData);
                } else {
                  
                    this.events.publish('product:noProductAvailable');
                }
                this.productSub.unsubscribe();
            }, error => {
                this.productSub.unsubscribe();
                console.dir(error);
            });
    }
    async loadMoreProducts(id: string, type: string) {
     
        let loadMoreProductsRef: AngularFirestoreCollection;
        loadMoreProductsRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit)
                .startAfter(this.lastInResponse));
        if(type === 'vendor') {
            loadMoreProductsRef = this.afs.collection('products', ref => ref
                .where('vendorId', '==', id)
                .where('status', '==', true)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit)
                .startAfter(this.lastInResponse));
        } else {
            const region = await this.sharedService.checkRegionIdForApi();
            if(region.vendorId && region.vendorId !== '') {
                loadMoreProductsRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .where('vendorId', '==', region.vendorId)
                .orderBy('sortedAt', 'desc')
                .limit(this.configService.environment.shopProductsLimit)
                .startAfter(this.lastInResponse));
            }
        }
        const loadMoreProductsSub = loadMoreProductsRef.snapshotChanges()
            .subscribe((response: any) => {
                if (!response.length) {
                   
                    this.events.publish('product:productsLimitReached');
                    return false;
                }
                this.lastInResponse = response[response.length - 1].payload.doc;
            
                for (const product of response) {
                    this.productsData.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
              
                this.events.publish('product:publishProducts', this.productsData);
                loadMoreProductsSub.unsubscribe();
            }, error => {
                loadMoreProductsSub.unsubscribe();
            });
    }
    async getProductsForAdminProducts() {
        this.productsDataForAdminProducts = [];
        this.afs.collection('products', ref => ref
            .orderBy('sortedAt', 'desc')
            .limit(environment.shopProductsLimit)
        ).snapshotChanges()
            .subscribe((response: any) => {
                if (!response.length) {
                    
                    this.events.publish('product:noProductsAvailable');
                    return false;
                }
                this.productsDataForAdminProducts = [];
                this.lastResponseForAdminProducts = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsDataForAdminProducts.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
               
                this.events.publish('product:publishProductsForAdminProducts', this.productsDataForAdminProducts);
            }, error => {
            });
    }
    async loadMoreProductsForAdminProducts() {
        
        this.afs.collection('products', ref => ref
            .orderBy('sortedAt', 'desc')
            .limit(environment.shopProductsLimit)
            .startAfter(this.lastResponseForAdminProducts)
        ).snapshotChanges()
            .subscribe((response: any) => {
                if (!response.length) {
                    
                    this.events.publish('product:productsForAdminProductsLimitReached');
                    return false;
                }
                this.productsDataForAdminProducts = [];
                this.firstResponseForAdminProducts = response[0].payload.doc
                this.lastResponseForAdminProducts = response[response.length - 1].payload.doc;
                
                for (const product of response) {
                    this.productsDataForAdminProducts.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                
                this.events.publish('product:publishProductsForAdminProducts', this.productsDataForAdminProducts);
            }, error => {
            });
    }
    async loadPreviousProductsForAdminProducts() {
       
        this.afs.collection('products', ref => ref
            .orderBy('sortedAt', 'desc')
            .endBefore(this.firstResponseForAdminProducts)
            .limitToLast(environment.shopProductsLimit)
        ).snapshotChanges()
            .subscribe((response: any) => {
                if (!response.length) {
                    
                    this.events.publish('product:previousProductsForAdminProductsLimitReached');
                    return false;
                }
                this.productsDataForAdminProducts = [];
                this.firstResponseForAdminProducts = response[0].payload.doc;
                this.lastResponseForAdminProducts = response[response.length - 1].payload.doc;
             
                for (const product of response) {
                    this.productsDataForAdminProducts.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
               
                this.events.publish('product:publishProductsForAdminProducts', this.productsDataForAdminProducts);
            }, error => {
            });
    }
    async getProductsForCategory(cid) {
        let productsData = [];
       
        const productRef = this.afs.collection('products', ref => ref.orderBy('sortedAt', 'desc').where("categories", "array-contains", cid));
        const productSnap = productRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        );
        productSnap.subscribe((productsData) => {
           
            if (productsData.length !== 0) {
                this.events.publish('product:publishProductsForCategory', productsData);
            } else {
                this.events.publish('product:noProducts');
            }
        })
    }

    async getProductWithId(id: string) {
        const productData = await this.afs.collection('products').doc(id).valueChanges().pipe(first()).toPromise();
        this.events.publish('product:publishgetProductWithId', productData);
    }
    async updateproductsPosition(id: string, changedDate: any) {
     
        await this.afs.doc(`products/${id}`).update({ sortedAt: changedDate });
        this.events.publish('product:updateProductPostionSucess');
    }
    async updateCategoriesPosition(id: string, changedDate: any) {
      
        await this.afs.doc(`categories/${id}`).update({ sortedAt: changedDate });
        this.events.publish('product:getAllCategories');
        this.events.publish('product:getAllCategoriesForShop');
        this.events.publish('product:updateCategoriesPostionSucess');
    }

    async updateStatus(id: string) {
      
        const uData: any = await this.afs.collection('products').doc(id).valueChanges().pipe(first()).toPromise();
        if (uData.status === true) {
            this.afs.doc(`products/${id}`).update({ status: false });
        } else {
            this.afs.doc(`products/${id}`).update({ status: true });
        }
    }
    async deleteProduct(id: string) {
    
        try {
            await this.afs.collection('products').doc(id).delete();
            this.events.publish('product:deleteSuccess', 'Success', 'Product deleted successfully!');
        } catch (error) {
         
            this.events.publish('product:deleteFailure', 'Failure', 'Product not deleted successfully!');
        }
    }
    async deleteCategory(id: string) {
       
        try {
            await this.afs.collection('categories').doc(id).delete();
            this.events.publish('product:deleteCategorySuccess');
            this.events.publish('product:getAllCategories');
            this.events.publish('product:getAllCategoriesForShop');
        } catch (error) {
         
            this.events.publish('product:deletecategoryFailure');
        }
    }
    async addCategory(catgeoryName: string, categoryImage: any, categoryStatus: boolean, banner: any) {
        const categoryMediaImage = {
            url: null,
            size: null,
            uploadedAt: null,
            categoryId: null
        };
        try {
            const categoryDoc = await this.afs.collection('categories').add({ name: catgeoryName, totalProducts: 0, sortedAt: new Date(), image: {}, status: categoryStatus });
            
            if (categoryImage.length !== 0) {
                categoryMediaImage.url = '';
                categoryMediaImage.size = categoryImage[0].imgSize;
                categoryMediaImage.uploadedAt = new Date();
                categoryMediaImage.categoryId = categoryDoc.id;
                const mediaDocRef = await this.afs.collection('media').doc('images').collection('categories').add(categoryMediaImage);
                const imgRef = this.fbStorage.ref(`categories/${categoryDoc.id}/image/` + mediaDocRef.id + '.png');
                await imgRef.putString(categoryImage[0].imgData, 'data_url');
                const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
               
                this.afs.collection('media').doc('images').collection('categories').doc(mediaDocRef.id).update({ url: downloadURL });
                await this.afs.doc(`categories/${categoryDoc.id}`).update({ image: { url: downloadURL, size: categoryImage[0].imgSize } });
            }
            if (banner.length) {
                const imgRef = this.fbStorage.ref(`categoriesBanner/${categoryDoc.id}/image/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(banner[0].imgData, 'data_url');
            }
            this.events.publish('product:addCategorySuccess');
            this.events.publish('product:getAllCategories');
            this.events.publish('product:getAllCategoriesForShop');
        } catch (err) {
            console.dir(err);
        }
    }
    async editCategory(catgeoryData: any, categoryImage: any, categoryStatus: boolean, banner: any) {
        const categoryMediaImage = {
            url: null,
            size: null,
            uploadedAt: null,
            categoryId: null
        };
        try {
            await this.afs.collection('categories').doc(catgeoryData.id).update({ name: catgeoryData.name, status: categoryStatus });
            if (categoryImage.length === 0 && banner.length === 0) {
                this.events.publish('product:editCategorySuccess');
                this.events.publish('product:getAllCategories');
                this.events.publish('product:getAllCategoriesForShop');
            } else {
                if (categoryImage.length) {
                    categoryMediaImage.url = '';
                    categoryMediaImage.size = categoryImage[0].imgSize;
                    categoryMediaImage.uploadedAt = new Date();
                    categoryMediaImage.categoryId = catgeoryData.id;
                    const mediaDocRef = await this.afs.collection('media').doc('images').collection('categories').add(categoryMediaImage);
                    const imgRef = this.fbStorage.ref(`categories/${catgeoryData.id}/image/` + mediaDocRef.id + '.png');
                    await imgRef.putString(categoryImage[0].imgData, 'data_url');
                    const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
                    
                    this.afs.collection('media').doc('images').collection('categories').doc(mediaDocRef.id).update({ url: downloadURL });
                    await this.afs.doc(`categories/${catgeoryData.id}`).update({ image: { url: downloadURL, size: categoryImage[0].imgSize } });
                }
                if (banner.length) {
                    const imgRef = this.fbStorage.ref(`categoriesBanner/${catgeoryData.id}/image/` + new Date().getTime().toString() + '.png');
                    await imgRef.putString(banner[0].imgData, 'data_url');
                }
                this.events.publish('product:editCategorySuccess');
                this.events.publish('product:getAllCategories');
                this.events.publish('product:getAllCategoriesForShop');
            }
        } catch (err) {
            console.dir(err);
            this.events.publish('product:editCategoryFailure');
        }
    }
    async getAllCategories() {
        const catgeoryRef = this.afs.collection('categories', ref => ref.orderBy('sortedAt', 'desc'));
        const catgeorySnap = catgeoryRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        );
        const catgeoryData = await catgeorySnap.pipe(first()).toPromise();
      
        if (!catgeoryData.length) {
            this.events.publish('product:noCategoryAvailable');
        } else {
            this.events.publish('product:publishAllCategoriesForAdmin', catgeoryData);
        }
    }
    async getAllCategoriesForShop() {
        try {
            let catgeoryRef: AngularFirestoreCollection;
            const region = await this.sharedService.checkRegionIdForApi();
            let regionId = region.regionId;
            if (regionId) {
                catgeoryRef = this.afs.collection('categories', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true)
                    .where('regionId', 'array-contains', regionId));
            } else {
                catgeoryRef = this.afs.collection('categories', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true));
            }

            const catgeorySnap = catgeoryRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            );
            const categoryData = await catgeorySnap.pipe(first()).toPromise();
            if (!categoryData.length) {
                this.events.publish('product:noCategoryAvailable');
            } else {
                this.events.publish('product:publishAllCategoriesForShop', categoryData);
            }
        } catch (error) {
            console.dir(error);
        }

    }
    async getAllCategoriesForSideMenu() {
        try {
            let catgeoryRef: AngularFirestoreCollection;
            const region = await this.sharedService.checkRegionIdForApi();
            let regionId = region.regionId;
            if (regionId) {
                catgeoryRef = this.afs.collection('categories', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true)
                    .where('regionId', 'array-contains', regionId));
            } else {
                catgeoryRef = this.afs.collection('categories', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true));
            }
            const catgeorySnap = catgeoryRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            );
            const catgeoryData = await catgeorySnap.pipe(first()).toPromise();
            return catgeoryData;
        } catch (error) {
            console.log(error);
        }
    }

    async getSubcategories(id) {
        try {
            
            const subcategoriesRef = this.afs.collection('categories').doc(id).collection('subcategories', ref => ref.orderBy('sortedAt', 'desc'));
            const subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            );
            subcategoriesSnap.subscribe((data) => {
                if (!data.length) {
                    
                    this.events.publish('product:noSubcategories');
                } else {
              
                    this.events.publish('product:publishSubcategories', data);
                }
            });
        } catch (error) {
            console.dir(error);
        }
    }
    async updateSubcategoriesPosition(id: string, changedDate: any, catId) {
      
        await this.afs.collection('categories').doc(catId).collection('subcategories').doc(id).update({ sortedAt: changedDate });
        this.events.publish('product:updateSubcategoriesPostionSucess');
    }

    async addSubcategory(data, categoryImage: any, catId, banner) {
        try {
            data['sortedAt'] = firebase.firestore.FieldValue.serverTimestamp();
            if (categoryImage.length === 0) {
                data['image'] = { url: 'assets/img/placeholder-img.jpg' };
            }
            const subcategoryDoc = await this.afs.collection('categories').doc(catId).collection('subcategories').add(data);
            if (categoryImage.length !== 0) {
                const imgRef = this.fbStorage.ref(`subcategories/${catId}/image/${subcategoryDoc.id}/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(categoryImage[0].imgData, 'data_url');
            }
            if (banner.length !== 0) {
                const imgRef = this.fbStorage.ref(`subCategoriesBanner/${catId}/banner/${subcategoryDoc.id}/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(banner[0].imgData, 'data_url');
            }
            this.events.publish('product:addSubcategorySuccess');
        } catch (err) {
            console.dir(err);
        }
    }

    async editSubcategory(data, image, subcatId, catId, banner) {
        try {
            const subcategoryDoc = await this.afs.collection('categories').doc(catId).collection('subcategories').doc(subcatId).update({
                name: data.name,
                status: data.status
            });
            if (image.length !== 0) {
                const imgRef = this.fbStorage.ref(`subcategories/${catId}/image/${subcatId}/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(image[0].imgData, 'data_url');
            }
            if (banner.length !== 0) {
                const imgRef = this.fbStorage.ref(`subCategoriesBanner/${catId}/banner/${subcatId}/` + new Date().getTime().toString() + '.png');
                await imgRef.putString(banner[0].imgData, 'data_url');
            }
            this.events.publish('product:editSubcategorySuccess');
        } catch (err) {
            console.dir(err);
        }
    }

    async deleteSubcategory(subcatId: string, catId: string) {
        try {
            await this.afs.collection('categories').doc(catId).collection('subcategories').doc(subcatId).delete();
            this.events.publish('product:deleteSubcategorySuccess');
        } catch (error) {
          
        }
    }

    async getProductsForSubcategory(subcatId) {
        let productsData = [];
        const productRef = this.afs.collection('products', ref => ref.orderBy('sortedAt', 'desc').where("categories", "array-contains", subcatId));
        const productSnap = productRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        );
        productSnap.subscribe((productsData) => {
          
            if (productsData.length !== 0) {
                this.events.publish('product:publishProductsForSubcategory', productsData);
            } else {
                this.events.publish('product:noProductsForSubcategory');
            }
        })
    }

    async changeSubcategoriesStatus(status, catId) {
        try {
            await this.afs.collection('categories').doc(catId).update({
                isSubcategories: status
            });
            this.events.publish('product:changeSubcategoriesStatusSuccess');
            this.events.publish('product:getAllCategories');
            this.events.publish('product:getAllCategoriesForShop');
        } catch (error) {
            console.dir(error);
        }
    }

    async getSubcategoriesInNewProduct(cid) {
        try {
            const subcategoriesRef = this.afs.collection('categories').doc(cid).collection('subcategories', ref => ref.orderBy('sortedAt', 'desc'));
            const subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            );
            const subcategoriesData = await subcategoriesSnap.pipe(first()).toPromise();
            return subcategoriesData;
        } catch (error) {
            console.dir(error);
        }
    }

    async getSubcategoriesForUser(id) {
        try {
          
            let catg:any = await this.afs.collection('categories').doc(id).valueChanges().pipe(first()).toPromise();
           
            if (catg.isSubcategories) {
                const subcategoriesRef = this.afs.collection('categories').doc(id).collection('subcategories', ref => ref
                .orderBy('sortedAt', 'desc')
                .where('status', '==', true));
            const subcategoriesSnap = subcategoriesRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            );
            subcategoriesSnap.subscribe((data) => {
                if (!data.length) {
                 
                    this.events.publish('product:noSubcategoriesForUser');
                } else {
                   
                    this.events.publish('product:publishSubcategoriesForUser', data);
                }
            });
                
            } else {
                this.events.publish('product:noSubcategoriesForUser');
            }
            
        } catch (error) {
            console.dir(error);
        }
    }

    async getAnalyticsProductsCount() {
        try {
            this.afs.collection('analytics').doc('products').valueChanges().subscribe((data: any) => {
                this.events.publish('product:publishAnalyticsProductsCount', data.count);
            });
        } catch (error) {
            console.dir(error);
        }

    }

    async makeProductCopies(copies, product) {
        try {
            let makeCopies = firebase.functions().httpsCallable('products-makeCopies');
            makeCopies({ copies: copies, product: product }).then((response) => {
               
                if (response.data.success) {
                    this.events.publish('product:makeProductCopiesSuccess');
                } else {
                    this.events.publish('product:makeProductCopiesFailure');
                }
            })
        } catch (error) {
            console.dir(error);
            this.events.publish('product:makeProductCopiesFailure');
        }
    }
    async getCategoriesData(cid: string) {
       
        try {
            const catData: any = await this.afs.collection('categories').doc(cid).valueChanges().pipe(first()).toPromise();

            this.events.publish('product:publishCategoriesData', catData);


        } catch (error) {
            console.dir(error);
        }
    }
    async getSubCategoriesData(cid: string, scid: string) {
        try {
            const data: any = await this.afs.collection('categories').doc(cid).collection('subcategories').doc(scid).valueChanges().pipe(first()).toPromise();
            this.events.publish('product:publishSubCategoriesData', data);
        } catch (error) {
            console.dir(error);
        }
    }

    async getSimilarProducts(product: any, pid: string) {
        let similarProducts: any = [];
        const keywords = product.searchKeywords && product.searchKeywords.length ? product.searchKeywords : [];
        const name = product.prodName || '';
        let similarQuery = name;
        if (keywords.length) {
            keywords.forEach(kw => {
                similarQuery = `${similarQuery} ${kw}`;
            });
        }
       
        const region = await this.sharedService.checkRegionIdForApi();
        let regionId = region.regionId;
        let vendorId = region.vendorId;
        let filters = 'status:true';
        if (regionId) {
            filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`
        }
        if (vendorId) {
            filters += ` AND vendorId:${vendorId}`
        }
        return new Promise(async (resolve, reject) => {
            this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
            this.index = this.client.initIndex(this.APP_PROJECT_ID);
            this.index.search('', { similarQuery: similarQuery, filters }).then((result) => {
                
                if (result.hits.length) {
                    for (const hit of result.hits) {
                        if (hit.objectID !== pid) {
                            similarProducts.push({ id: hit.objectID, data: hit });
                        }
                    }
                }
                resolve(similarProducts);
            }).catch(async (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    async getCategoriesWithSubcategoriesForMenu() {
        
        let list: any = [];
        return new Promise(async (resolve, reject) => {
            const storageCategories = JSON.parse(localStorage.getItem("categories")) || [];
            if (!storageCategories.length) {
                list = await this.setCategoriesInLocalStorage();
            } else {
                list = storageCategories;
            }
            resolve(list);
        });
    }

    async setCategoriesInLocalStorage() {
        return new Promise(async (resolve, reject) => {
            let list: any = [];
            const region = await this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let categoryRef: AngularFirestoreCollection;
                if (regionId) {
                    categoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId))
                } else {
                    categoryRef = this.afs.collection('categories', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                }
                const categories = await categoryRef.snapshotChanges().pipe(
                    map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                for (const c of categories) {
                    if (c.isSubcategories) {
                        const subcategories: any = await this.getSubCategoriesForMenu(c.id);
                        let sublist = [];
                        if (subcategories.length) {
                            for (const sc of subcategories) {
                                sublist.push({ id: sc.id, name: sc.name });
                            }
                        }
                        list.push({ id: c.id, name: c.name, sublist });
                    } else {
                        list.push({ id: c.id, name: c.name, sublist: [] });
                    }
                }
                localStorage.setItem("categories", JSON.stringify(list));
                resolve(list);
        });
    }

    async getSubCategoriesForMenu(id) {
        try {
           
            const subcategoriesRef = this.afs.collection('categories').doc(id).collection('subcategories', ref => 
            ref.orderBy('sortedAt', 'desc')
            .where('status', '==', true)
            );
            const subcategories = await subcategoriesRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return { id, ...data };
                }))
            ).pipe(first()).toPromise();
            return subcategories || [];
        } catch (error) {
            console.dir(error);
        }
    }


    async saveAnalytics(pid: string) {
        const uid = await this.sharedService.getStorageUid();
        if(uid) {
            const data = {
                lastAccessAt: new Date(),
                source: 'web'
            };
            this.afs.collection('users').doc(uid).collection('analytics').doc('products').collection('data').doc(pid).set(data);
        }
    }

    async notifyProduct(data: any) {
        const uid = await this.sharedService.getStorageUid();
        if(data.parentId) {
            this.afs.collection('products').doc(data.parentId).collection('options').doc(data.id).collection('notifications').doc(uid).set({ createdAt: new Date() });
        } else {
            this.afs.collection('products').doc(data.id).collection('notifications').doc(uid).set({ createdAt: new Date() });
        }
    }

    async getDescriptionSections(productId: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            try {
                const appSections = [];
                const sectionsDoc: any = await this.afs.collection('products').doc(productId).collection('sections').doc('productWidgets').valueChanges().pipe(first()).toPromise();
                if(sectionsDoc && sectionsDoc.sections && sectionsDoc.sections.length) {
                    sectionsDoc.sections.forEach(section => {
                        if(section.location === 'app' || section.location === 'all') {
                            appSections.push(section)
                        }
                    });
                }
                resolve(appSections);
            } catch (error) {
                console.dir(error);
                error['location'] = 'product-service:getDescriptionSections';
                //this.logglyService.log(error);
                resolve([]);
            }
        });
    }

    removeSubscriptions() {
        this.events.unsubscribe('product:addProduct');
        this.events.unsubscribe('product:getProducts');
        this.events.unsubscribe('product:getProductWithId');
        this.events.unsubscribe('product:updateStatus');
        this.events.unsubscribe('product:editProduct');
        this.events.unsubscribe('product:deleteProduct');
        this.events.unsubscribe('product:loadMoreProducts');
        this.events.unsubscribe('product:addCategory');
        this.events.unsubscribe('product:getAllCategories');
        this.events.unsubscribe('product:deleteCategory');
        this.events.unsubscribe('product:editCategory');
        this.events.unsubscribe('product:getAllCategoriesForShop');
        this.events.unsubscribe('product:getProductsForCategory');
        this.events.unsubscribe('product:getProductsForAdminProducts');
        this.events.unsubscribe('product:loadMoreProductsForAdminProducts');
    }
}
