import { Component, OnInit } from '@angular/core';
import { ModalController, Events, AlertController, LoadingController, ToastController, Platform } from '@ionic/angular';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { CreateSubscriptionPage } from '../user-product-subscription/create-subscription/create-subscription.page';
import { ConfigService } from 'src/app/services/config/config.service';
import { SharedService } from '../services/shared/shared.service';
import { CartService } from '../services/cart/cart.service';
import { UtilsService } from '../services/utils/utils.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedNewService } from '../services/shared-new/shared-new.service';
import { AppointmentSchedulesPage } from '../appointment-schedules/appointment-schedules.page';

@Component({
    selector: 'app-pricelist-modal',
    templateUrl: './pricelist-modal.page.html',
    styleUrls: ['./pricelist-modal.page.scss'],
})
export class PricelistModalPage implements OnInit {
    product: any;
    userId: string;
    loading: any;
    cartProducts: any = [];
    listOfWeights: any = [];
    mainListOfWeightsAndPrices: any = [];
    parentProductId: string;
    currencyCode: any;
    forSubscribe: boolean;
    subSettings: any;
    priceForRetail = false;
    SHARED_LABELS: any = {};
    PRICELIST_MODAL_LABLES: any = {};
    useThumb: boolean;
    productURL = '';
    constructor(private modalController: ModalController,
        private events: Events,
        private alertController: AlertController,
        private loadingController: LoadingController,
        private userService: UserService,
        private router: Router,
        private storage: Storage,
        private configService: ConfigService,
        private toastController: ToastController,
        private sharedService: SharedService,
        private utilsService: UtilsService,
        private labelService: LabelService,
        private platform: Platform,
        private cartService: CartService,
        private sharedNewService: SharedNewService) { }

    ngOnInit() {
        this.platform.ready().then(() => {

            let deviceWidth = this.platform.width();
            if (deviceWidth > 786) {
                this.useThumb = false;

            } else {
                this.useThumb = true;
            }
        });

        this.currencyCode = this.configService.environment.currencyCode;
        this.priceForRetail = this.configService.environment.priceForRetail;
    }

    modalDismiss() {
        this.modalController.dismiss();
    }

    goToPage(page: string) {
        this.modalDismiss();
        this.router.navigate([page]);
    }

    ionViewWillEnter() {
        this.userId = this.userService.getUserId();
        this.initialDisplayOfWeights();
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.PRICELIST_MODAL_LABLES = this.labelService.labels['SHARED'];
    }

    getDiscountPercent(discountedPrice, prodPrice) {
        if (prodPrice > discountedPrice) {
            const discount = Math.round(((prodPrice - discountedPrice) / prodPrice) * 100);
            return discount;
        } else {
            return 0;
        }
    }

    async initialDisplayOfWeights() {
        const subRole = await this.storage.get('userSubRole');
        this.product.data = await this.sharedService.updateProductPrices(this.product.data, subRole);
        for (const pl of this.product.data.priceList) {
            const index = this.cartProducts.findIndex(c => c.productId === this.product.id && c.pack.weight === pl.weight);
            if(index !== -1) {
                pl.cartQty = this.cartProducts[index].quantity;
                pl.cartId = this.cartProducts[index].id;
            }
        }
        this.mainListOfWeightsAndPrices = this.product.data.priceList;
    }



    async addProductToCart(index) {
        const isCartAvailable = await this.cartService.checkCartAvailability(this.product.data.inactiveByVendor);
        if (isCartAvailable) {
            const cartObj = this.cartService.getPriceListCartObj(this.product, index);
            const cartId = await this.cartService.addProductToCart(cartObj);
            this.mainListOfWeightsAndPrices[index].cartId = cartId;
            this.mainListOfWeightsAndPrices[index].cartQty = 1;
        }
    }

    async decrementQuantity(index: number) {
        if (this.mainListOfWeightsAndPrices[index].cartQty > 1) {
            this.mainListOfWeightsAndPrices[index].cartQty -= 1;
            this.cartService.updateCartState(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
            this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] || null;
            clearTimeout(this.mainListOfWeightsAndPrices[index]['cartUpdateTimer']);
            this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = setTimeout(() => {
                this.cartService.updateCartProduct(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
            }, 800);
        } else {
            this.cartService.deleteCartProduct(this.mainListOfWeightsAndPrices[index].cartId);
        }
    }
    async incrementQuantity(index: number) {
        this.mainListOfWeightsAndPrices[index].cartQty += 1;
        this.cartService.updateCartState(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
        this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] || null;
        clearTimeout(this.mainListOfWeightsAndPrices[index]['cartUpdateTimer']);
        this.mainListOfWeightsAndPrices[index]['cartUpdateTimer'] = setTimeout(() => {
            this.cartService.updateCartProduct(this.mainListOfWeightsAndPrices[index].cartId, this.mainListOfWeightsAndPrices[index].cartQty);
        }, 800);
    }


    closePriceListModal() {
        this.modalController.dismiss();
    }
    async goToCart() {
        const isCartAvailable = await this.cartService.checkCartAvailability();
        if (isCartAvailable) {
            this.closePriceListModal();
            this.router.navigate(['user-cart']);
        }
    }
    async presentAlert(msg: string) {
        const alert = await this.alertController.create({
            message: msg,
            buttons: this.SHARED_LABELS['ok']
        });

        await alert.present();
    }
    async presentLoading() {
        this.loading = await this.loadingController.create({
            message: this.SHARED_LABELS['please_wait'],
            duration: 5000
        });
        await this.loading.present();
    }
    getDiscountedProduct(price, discount) {
        let discountedPrice = Math.ceil(price - (price * ((discount * 1) / 100)));
        return discountedPrice;
    }

    maxQtyReached() {
        this.presentToast(`${this.PRICELIST_MODAL_LABLES['max_qty_reached']}`);
    }

    async presentToast(msg: string) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    subscribe(i: number) {
        this.product.data.prodPrice = this.product.data.priceList[i].price;
        this.product.data.discountedPrice = this.product.data.priceList[i].discountedPrice;
        this.product.data['variantValue'] = this.product.data.priceList[i].weight;
        this.product.data['productCode'] = 'sku' in this.product.data.priceList[i] ? this.product.data.priceList[i].sku : this.product.data.productCode ? this.product.data.productCode : '';
        this.product.data['barcodeNo'] = this.product.data.priceList[i].barcodeNo || '';
        this.closePriceListModal();
        this.modalController.create({
            component: CreateSubscriptionPage,
            cssClass: 'custom-modal',
            componentProps: {
                product: this.product,
                subSettings: this.subSettings,
            }
        }).then(modalEl => { modalEl.present(); });
    }

    isProductInCart(product) {
        return this.cartService.isProductInCart(product)
    }

    showQtyLeftMsg(qty: number) {
        return this.sharedNewService.showPriceListQtyLeftMsg(qty || 0);
    }

    getPriceListWeight(element) {
        if (this.product.data.variantType !== 'pieces') {
            return element.weight;
        } else {
            if ('name' in element) {
                return element.name;
            } else {
                return element.weight;
            }
        }
    }

        
    async bookAppointment(index: number) {
        const variant = this.product.data.appointment.schedules.variant.filter(s => s.name === this.product.data.priceList[index].weight);
        const modal = await this.modalController.create({
            component: AppointmentSchedulesPage,
            componentProps: { 
                item: {
                    name: this.product.data.prodName,
                    price: this.product.data.priceList[index].discountedPrice,
                    coverPic: this.product.data.coverPic,
                    id: this.product.id,
                    variant: this.product.data.priceList[index].weight
                },
                vendor: {
                    id: this.product.data.vendorId || ''
                },
                maxDays: variant.length ? variant[0].maxDays : 0,
                schedules: variant.length ? variant[0].schedules: []
            }
        });
        await modal.present();    
        modal.onDidDismiss()
        .then((data:any) => {
            if (data.data) {
                if (data.data.booked) {
                    setTimeout(() => {
                        this.modalDismiss();
                    }, 100);
                }
            }
      });
    }
    
}
