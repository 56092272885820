import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { convertSnaps } from '../db-utils';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { Storage } from '@ionic/storage';
import { AlertController, LoadingController } from '@ionic/angular';
import { UtilsService } from '../utils/utils.service';
import { LabelService } from '../label/label.service';
import { ConfigService } from '../config/config.service';
import * as moment from 'moment';
import { VendorService } from '../vendor/vendor.service';
import { Router } from '@angular/router';
import { SharedNewService } from '../shared-new/shared-new.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../loggly-logger/loggly-logger.service";
import * as i3 from "@ionic/storage";
import * as i4 from "@ionic/angular";
import * as i5 from "../label/label.service";
import * as i6 from "../utils/utils.service";
import * as i7 from "../config/config.service";
import * as i8 from "../vendor/vendor.service";
import * as i9 from "@angular/router";
import * as i10 from "../shared-new/shared-new.service";
export class CartService {
    constructor(afs, logglyService, storage, alertController, labelService, loadingController, utilsService, configService, vendorService, router, sharedNewService) {
        this.afs = afs;
        this.logglyService = logglyService;
        this.storage = storage;
        this.alertController = alertController;
        this.labelService = labelService;
        this.loadingController = loadingController;
        this.utilsService = utilsService;
        this.configService = configService;
        this.vendorService = vendorService;
        this.router = router;
        this.sharedNewService = sharedNewService;
        this.cartProductsUpdated = new Subject();
        this.cartProducts = [];
        this.userRef = this.afs.collection('users');
        this.currentCategory = null;
        this.exclusiveVendorId = '';
    }
    setCartProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const role = yield this.storage.get('userRole');
                if (role === 'user') {
                    const uid = yield this.storage.get('uid');
                    if (uid && uid !== undefined) {
                        const cartRef = this.afs.collection('users').doc(uid).collection('cart');
                        const cartProducts = yield cartRef.snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                        this.cartProducts = cartProducts;
                        this.cartProductsUpdated.next([...this.cartProducts]);
                        this.setExclusivity();
                    }
                }
            }
            catch (err) {
                console.dir(err);
                err['location'] = 'cart-service:setUserCartProducts';
                this.logglyService.log(err);
            }
        });
    }
    fetchCartProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.storage.get('uid');
            const cartRef = this.afs.collection('users').doc(uid).collection('cart');
            const cartProducts = yield cartRef.snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
            return cartProducts;
        });
    }
    getCartProductsListener() {
        return this.cartProductsUpdated.asObservable();
    }
    getCartProducts() {
        return this.cartProducts;
    }
    updateCartProduct(cartID, qty) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.storage.get('uid');
            yield this.afs.collection('users').doc(uid).collection('cart').doc(cartID).update({ quantity: qty });
        });
    }
    deleteCartProduct(cartID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.storage.get('uid');
            yield this.afs.collection('users').doc(uid).collection('cart').doc(cartID).delete();
            const index = this.cartProducts.findIndex(c => c.id === cartID);
            this.cartProducts.splice(index, 1);
            this.cartProductsUpdated.next([...this.cartProducts]);
            if (!this.cartProducts.length) {
                this.exclusiveVendorId = '';
            }
        });
    }
    presentCartLoader() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cartLoader = yield this.loadingController.create({
                cssClass: 'cart-loader',
                spinner: null,
                duration: 1000
            });
            yield this.cartLoader.present();
        });
    }
    addProductToCart(cartObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.presentCartLoader();
            let action = 'add';
            if (this.productAlreadyAddedToCart(cartObj)) {
                action = 'not_add';
                this.alreadyAddedAlert();
            }
            //check exclusivity of category
            if (action === 'add') {
                action = yield this.checkCartExclusivity(cartObj);
                if (action === 'add') {
                    const uid = yield this.storage.get('uid');
                    const cartRef = yield this.afs.collection('users').doc(uid).collection('cart').add(cartObj);
                    this.cartProducts.push(Object.assign({ id: cartRef.id }, cartObj));
                    this.cartProductsUpdated.next([...this.cartProducts]);
                    return cartRef.id;
                }
            }
            if (this.cartLoader) {
                this.cartLoader.dismiss();
            }
            return;
        });
    }
    alreadyAddedAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: this.labelService.labels['PRODUCT_DETAILS']['already_added_to_cart'],
                buttons: [{
                        text: this.labelService.labels['PRODUCT_DETAILS']['continue_alert_btn'],
                        handler: () => { }
                    }, {
                        text: this.labelService.labels['PRODUCT_DETAILS']['go_to_cart_alert_btn'],
                        handler: () => {
                            this.router.navigate(['user-cart']);
                        }
                    }]
            });
            yield alert.present();
        });
    }
    productAlreadyAddedToCart(cartObj) {
        let added = false;
        if ('pack' in cartObj) {
            added = this.cartProducts.some(c => c.productId === cartObj.productId && c.pack.weight === cartObj.pack.weight);
        }
        else {
            added = this.cartProducts.some(c => c.productId === cartObj.productId);
        }
        return added;
    }
    updateCartState(cartID, qty) {
        const index = this.cartProducts.findIndex(c => c.id === cartID);
        this.cartProducts[index].quantity = qty;
        this.cartProductsUpdated.next([...this.cartProducts]);
    }
    getCartPrice(cartProducts) {
        let price = 0;
        for (const pdt of cartProducts) {
            if (pdt.status && (pdt.totalQty === '0' && !pdt.stopWhenNoQty || pdt.totalQty !== '0')) {
                price += pdt.price * pdt.quantity;
            }
        }
        return price;
    }
    removeHtml(desc) {
        const tagsRemoved = desc.replace(/(<([^>]+)>)/ig, '');
        const plainTxt = document.createElement('textarea');
        plainTxt.innerHTML = tagsRemoved;
        return plainTxt.value;
    }
    isProductInCart(product) {
        if (!product.hasOwnProperty('cartId') || product.cartId === '') {
            return false;
        }
        else {
            return this.cartProducts.some(c => c.id === product.cartId);
        }
    }
    checkCartAvailability(inactiveByVendor) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (inactiveByVendor) {
                this.presentAlert('', `${this.labelService.labels['SHARED']['vendor_product_inactive']}`);
                return false;
            }
            const userId = yield this.storage.get('uid');
            if (!userId || userId === undefined) {
                this.utilsService.openLoginModal();
                return false;
            }
            else {
                const role = yield this.storage.get('userRole');
                if (role === 'admin') {
                    this.presentAlert('', 'Cart is available only for user not for admin.');
                    return false;
                }
                else if (role === 'deliveryAgent') {
                    this.presentAlert('', 'Cart is available only for user not for delivery agent.');
                    return false;
                }
                else {
                    return true;
                }
            }
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.labelService.labels['SHARED']['please_wait'],
            });
            yield this.loading.present();
        });
    }
    presentAlert(subHeader, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                subHeader: subHeader,
                message: msg,
                buttons: [this.labelService.labels['SHARED']['ok']],
                cssClass: 'alert-msg-w-header'
            });
            yield alert.present();
        });
    }
    resetCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const batch = this.afs.firestore.batch();
                const uid = yield this.storage.get('uid');
                const cartRef = this.afs.collection('users').doc(uid).collection('cart');
                for (const product of this.cartProducts) {
                    batch.delete(cartRef.doc(product.id).ref);
                }
                batch.commit().then(() => {
                    this.cartProducts = [];
                    this.cartProductsUpdated.next([]);
                    resolve(true);
                }).catch((error) => {
                    console.log(error);
                    resolve(false);
                });
            }));
        });
    }
    checkCartExclusivity(cartObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!cartObj.vendorId && this.exclusiveVendorId) {
                    console.log('no vendorId but cart is exc');
                    const res = yield this.showReplaceAlert();
                    if (res === 'add') {
                        this.exclusiveVendorId = '';
                    }
                    resolve(res);
                }
                else if (!cartObj.vendorId && !this.exclusiveVendorId) {
                    console.log('no vendorid and cart is not exc');
                    resolve('add');
                }
                else {
                    const exclusiveVendors = this.vendorService.vendorSettings.exclusiveVendors;
                    if (exclusiveVendors.length) {
                        if (!this.cartProducts.length) {
                            if (cartObj.vendorId && exclusiveVendors.includes(cartObj.vendorId)) {
                                console.log('product is exc and no product in cart');
                                this.exclusiveVendorId = cartObj.vendorId;
                            }
                            resolve('add');
                        }
                        else {
                            if (this.exclusiveVendorId === cartObj.vendorId) {
                                console.log('same vendor product');
                                resolve('add');
                            }
                            else {
                                const isExclusive = exclusiveVendors.includes(cartObj.vendorId);
                                if (isExclusive) {
                                    console.log('product is exc and cart has products');
                                    const res = yield this.showReplaceAlert();
                                    if (res === 'add') {
                                        this.exclusiveVendorId = cartObj.vendorId;
                                    }
                                    resolve(res);
                                }
                                else {
                                    console.log('product is not exc');
                                    if (this.exclusiveVendorId) {
                                        console.log('but cart is exc');
                                        const res = yield this.showReplaceAlert();
                                        if (res === 'add') {
                                            this.exclusiveVendorId = '';
                                        }
                                        resolve(res);
                                    }
                                    else {
                                        console.log('and cart is also not exc');
                                        this.exclusiveVendorId = '';
                                        resolve('add');
                                    }
                                }
                            }
                        }
                    }
                    else {
                        console.log('no exc vendors');
                        this.exclusiveVendorId = '';
                        resolve('add');
                    }
                }
                console.log('this.exclusiveVendorId', this.exclusiveVendorId);
            }));
        });
    }
    showReplaceAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const confirm = yield this.replaceCartAlert();
                if (confirm) {
                    yield this.presentLoading();
                    const res = yield this.resetCart();
                    this.loading.dismiss();
                    if (res) {
                        resolve('add');
                    }
                    else {
                        this.presentAlert(this.labelService.labels['SHARED']['oops'], this.labelService.labels['SHARED']['some_issue_msg']);
                        resolve('not_add');
                    }
                }
                else {
                    resolve('not_add');
                }
            }));
        });
    }
    replaceCartAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const alert = yield this.alertController.create({
                    subHeader: this.labelService.labels['CART_SERVICE']['replace_alert_header'],
                    message: this.labelService.labels['CART_SERVICE']['replace_alert_msg'],
                    cssClass: 'alert-msg-w-header',
                    buttons: [
                        {
                            text: this.labelService.labels['CART_SERVICE']['no'],
                            handler: () => resolve(false)
                        },
                        {
                            text: this.labelService.labels['CART_SERVICE']['yes'],
                            handler: () => resolve(true)
                        }
                    ]
                });
                yield alert.present();
            }));
        });
    }
    setExclusivity() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // let exclusiveObj = await this.storage.get('exclusiveObj');
            // if (exclusiveObj) {
            //     exclusiveObj['exclusivity'] = exclusiveObj.category;
            //     this.currentCategory = exclusiveObj.categoryId;
            //     this.storage.set('exclusiveObj', exclusiveObj);
            // }
            let cartVendorId = '';
            this.cartProducts.forEach(product => {
                if (product.vendorId) {
                    cartVendorId = product.vendorId;
                }
            });
            const exclusiveVendors = yield this.vendorService.getAllExclusiveVendors();
            if (exclusiveVendors.length) {
                const isExclusive = exclusiveVendors.includes(cartVendorId);
                if (isExclusive) {
                    this.exclusiveVendorId = cartVendorId;
                }
            }
            else {
                this.exclusiveVendorId = '';
            }
        });
    }
    getCartObj(product, productID, quantity) {
        let cartObj = {
            name: product.prodName,
            quantity: quantity || 1,
            img: product.coverPic,
            productId: productID,
            commentMsg: '',
            commentImgs: [],
            maxQty: product.maxQty ? product.maxQty : 0,
            minQty: product.minQty ? product.minQty : 1,
            gst: product.gst ? product.gst : 0,
            status: typeof product.status !== 'undefined' ? product.status : true,
            stopWhenNoQty: product.hasOwnProperty('stopWhenNoQty') && typeof product.stopWhenNoQty !== 'undefined' ? product.stopWhenNoQty : false,
            totalQty: product.productQty ? product.productQty : '',
            hsn: product.hsnCode ? product.hsnCode : '',
            sku: product.productCode ? product.productCode : '',
            barcode: product.barcode ? product.barcode : '',
            shippingWt: product.shippingWeight || 0,
            barcodeNo: product.barcodeNo || '',
            gstExclusive: product.gstExclusive || false,
            extraCharges: ('extraCharges' in product) && (typeof product.extraCharges === 'object') && product.extraCharges.active ? product.extraCharges : { charge: 0 },
            isCod: 'isCod' in product ? product.isCod : true,
            vendorId: product.vendorId || '',
            priceSlabs: 'priceSlabs' in product ? { active: product.priceSlabs.active } : { active: false }
        };
        let desc = this.removeHtml(product.prodDesc);
        cartObj['description'] = desc;
        if (product.discountedPrice && (product.discountedPrice !== product.prodPrice)) {
            cartObj['mrpPrice'] = product.prodPrice;
            cartObj['price'] = product.discountedPrice;
        }
        else {
            cartObj['price'] = product.prodPrice;
        }
        if (product.hasOwnProperty('color') && product.color.hasOwnProperty('name')) {
            cartObj['color'] = product.color;
        }
        cartObj = this.priceSlabsCheck(cartObj, product);
        return cartObj;
    }
    getPriceListCartObj(product, variantIndex = 0, quantity = 1) {
        let cartObj = {
            name: product.data.prodName,
            quantity: quantity,
            img: this.getCoverPic(product.data, variantIndex),
            description: product.data.priceList[variantIndex].weight,
            commentMsg: '',
            commentImgs: [],
            maxQty: product.data.maxQty ? product.data.maxQty : 0,
            minQty: product.data.minQty ? product.data.minQty : 1,
            gst: product.data.gst ? product.data.gst : 0,
            status: typeof product.data.status !== 'undefined' ? product.data.status : true,
            stopWhenNoQty: product.data.hasOwnProperty('stopWhenNoQty') && typeof product.data.stopWhenNoQty !== 'undefined' ? product.data.stopWhenNoQty : false,
            totalQty: product.data.priceList[variantIndex].totalQuantity ? product.data.priceList[variantIndex].totalQuantity : '',
            hsn: product.data.hsnCode ? product.data.hsnCode : '',
            sku: 'sku' in product.data.priceList[variantIndex] ? product.data.priceList[variantIndex].sku : product.data.productCode ? product.data.productCode : '',
            barcode: product.data.priceList[variantIndex].barcode ? product.data.priceList[variantIndex].barcode : '',
            shippingWt: product.data.priceList[variantIndex].shippingWeight || 0,
            barcodeNo: product.data.priceList[variantIndex].barcodeNo || '',
            gstExclusive: product.data.gstExclusive || false,
            extraCharges: ('extraCharges' in product.data) && (typeof product.data.extraCharges === 'object') && product.data.extraCharges.active ? product.data.extraCharges : { charge: 0 },
            isCod: 'isCod' in product.data ? product.data.isCod : true,
            vendorId: product.data.vendorId || '',
            priceSlabs: 'priceSlabs' in product.data ? { active: product.data.priceSlabs.active } : { active: false },
            pack: {
                weight: product.data.priceList[variantIndex].weight,
                variantType: product.data.variantType ? product.data.variantType : 'variant'
            }
        };
        if (product.data.variantType && product.data.variantType === 'pieces') {
            if (product.data.priceList[variantIndex].discountedPrice && product.data.priceList[variantIndex].discountedPrice !== product.data.priceList[variantIndex].price) {
                cartObj['mrpPrice'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj['price'] = product.data.priceList[variantIndex].discountedPrice * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['price'] = product.data.priceList[variantIndex].discountedPrice * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['perPcPrice'] = product.data.priceList[variantIndex].discountedPrice;
            }
            else {
                cartObj['price'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['price'] = product.data.priceList[variantIndex].price * parseInt(product.data.priceList[variantIndex].weight);
                cartObj.pack['perPcPrice'] = product.data.priceList[variantIndex].price;
            }
        }
        else {
            if (product.data.priceList[variantIndex].discountedPrice && product.data.priceList[variantIndex].discountedPrice !== product.data.priceList[variantIndex].price) {
                cartObj['mrpPrice'] = product.data.priceList[variantIndex].price;
                cartObj['price'] = product.data.priceList[variantIndex].discountedPrice;
                cartObj.pack['price'] = product.data.priceList[variantIndex].discountedPrice;
            }
            else {
                cartObj['price'] = product.data.priceList[variantIndex].price;
                cartObj.pack['price'] = product.data.priceList[variantIndex].price;
            }
        }
        if (product.data.hasOwnProperty('color') && product.data.color.hasOwnProperty('name')) {
            cartObj['color'] = product.data.color;
        }
        if (product.parentProductId) {
            cartObj['parentProductId'] = product.parentProductId;
            cartObj['productId'] = product.id;
        }
        else {
            cartObj['productId'] = product.id;
        }
        cartObj = this.priceSlabsCheck(cartObj, product.data);
        return cartObj;
    }
    getCoverPic(product, index) {
        const variant = product.priceList[index];
        return 'images' in variant && variant.images.length ? variant.images[0] : product.coverPic;
    }
    updatedCartFromBackend(cartProducts, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const products = JSON.parse(JSON.stringify(cartProducts));
                    let cartPdts = [];
                    let pdtsIds = [];
                    let subPdtIds = [];
                    let pdts = [];
                    let subPdts = [];
                    let updatedCartPdts = [];
                    let subscribedPdts = [];
                    let membership = [];
                    for (const p of products) {
                        if (p.orderType === 'subscription') {
                            subscribedPdts.push(p);
                        }
                        else if (p.orderType === 'membership') {
                            membership.push(p);
                        }
                        else {
                            cartPdts.push(p);
                            if (!p.parentProductId) {
                                pdtsIds.push(p.productId);
                            }
                            else {
                                subPdtIds.push({
                                    parentId: p.parentProductId,
                                    pid: p.productId
                                });
                            }
                        }
                    }
                    if (pdtsIds.length) {
                        for (let index = 0; index < pdtsIds.length; index++) {
                            let productRef = yield this.afs.collection('products').doc(pdtsIds[index]).valueChanges().pipe(first()).toPromise();
                            if (productRef) {
                                productRef['id'] = pdtsIds[index];
                                pdts.push(productRef);
                            }
                        }
                    }
                    if (subPdtIds.length) {
                        for (let index = 0; index < subPdtIds.length; index++) {
                            let subProductRef = yield this.afs.collection('products').doc(subPdtIds[index].parentId).collection('options').doc(subPdtIds[index].pid).valueChanges().pipe(first()).toPromise();
                            if (subProductRef) {
                                subProductRef['id'] = subPdtIds[index].pid;
                                subPdts.push(subProductRef);
                            }
                        }
                    }
                    let allUpdatedPdts = pdts.concat(subPdts);
                    if (allUpdatedPdts.length) {
                        updatedCartPdts = yield this.getUpdatedPdts(allUpdatedPdts, cartPdts, page);
                    }
                    if (subscribedPdts.length > 0) {
                        updatedCartPdts = updatedCartPdts.concat(subscribedPdts);
                    }
                    if (membership.length > 0) {
                        updatedCartPdts = updatedCartPdts.concat(membership);
                    }
                    resolve(updatedCartPdts);
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'cart-service:updatedCartFromBackend';
                    this.logglyService.log(error);
                    resolve([]);
                }
            }));
        });
    }
    getUpdatedPdts(pdts, cartPdts, page) {
        return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let c of cartPdts) {
                const productIndex = pdts.findIndex(p => p.id === c.productId);
                if (productIndex !== -1) {
                    const dbProduct = pdts[productIndex];
                    c.name = dbProduct.prodName;
                    c.maxQty = dbProduct.maxQty ? dbProduct.maxQty : 0;
                    c.minQty = dbProduct.minQty ? dbProduct.minQty : 1;
                    c.status = dbProduct.inactiveByVendor ? false : typeof dbProduct.status !== undefined ? dbProduct.status : true;
                    c.stopWhenNoQty = dbProduct.hasOwnProperty('stopWhenNoQty') && typeof dbProduct.stopWhenNoQty !== undefined ? dbProduct.stopWhenNoQty : false;
                    c.retailDiscount = dbProduct.hasOwnProperty('retailDiscount') ? dbProduct.retailDiscount : 0;
                    c.retailDiscountType = dbProduct.retailDiscountType || 'percentage';
                    c.extraCharges = ('extraCharges' in dbProduct) && (typeof dbProduct.extraCharges === 'object') && dbProduct.extraCharges.active ? dbProduct.extraCharges : { charge: 0 };
                    c.img = dbProduct.coverPic;
                    c.priceSlabs = 'priceSlabs' in dbProduct ? { active: dbProduct.priceSlabs.active } : { active: false };
                    let deal = yield this.checkLimitedTimeDeal(dbProduct);
                    if (deal.dealAllowed) {
                        if (deal.discount > 0) {
                            c.dealDiscount = deal.discount;
                        }
                    }
                    if (!c.hasOwnProperty('pack')) {
                        c.totalQty = dbProduct.productQty ? dbProduct.productQty : '';
                        if (parseInt(dbProduct.productQty) && (c.quantity > parseInt(dbProduct.productQty))) {
                            c.quantity = parseInt(dbProduct.productQty);
                        }
                        if (dbProduct.discountedPrice && dbProduct.discountedPrice !== dbProduct.prodPrice) {
                            c.price = dbProduct.discountedPrice;
                            c.mrpPrice = dbProduct.prodPrice;
                        }
                        else {
                            c.price = dbProduct.prodPrice;
                        }
                    }
                    else {
                        if (c.pack.variantType !== 'pieces') {
                            dbProduct.priceList.forEach((pl) => {
                                if (pl.weight === c.pack.weight) {
                                    c.totalQty = pl.totalQuantity ? pl.totalQuantity : '';
                                    if (parseInt(pl.totalQuantity) && (c.quantity > parseInt(pl.totalQuantity))) {
                                        c.quantity = parseInt(pl.totalQuantity);
                                    }
                                    if (pl.discountedPrice && pl.discountedPrice !== pl.price) {
                                        c.price = pl.discountedPrice;
                                        c.mrpPrice = pl.price;
                                        c.pack.price = pl.price;
                                    }
                                    else {
                                        c.price = pl.price;
                                        c.pack.price = pl.price;
                                    }
                                }
                            });
                        }
                        else {
                            dbProduct.priceList.forEach((pl) => {
                                if (pl.weight === c.pack.weight) {
                                    c.totalQty = pl.totalQuantity ? pl.totalQuantity : '';
                                    if (parseInt(pl.totalQuantity) && (c.quantity > parseInt(pl.totalQuantity))) {
                                        c.quantity = parseInt(pl.totalQuantity);
                                    }
                                    if (pl.discountedPrice && pl.discountedPrice !== pl.price) {
                                        c.price = pl.discountedPrice * parseInt(pl.weight);
                                        c.mrpPrice = pl.price * parseInt(pl.weight);
                                        c.pack.price = pl.discountedPrice * parseInt(pl.weight);
                                        c.pack.perPcPrice = pl.discountedPrice;
                                    }
                                    else {
                                        c.price = pl.price * parseInt(pl.weight);
                                        c.pack.price = pl.price * parseInt(pl.weight);
                                        c.pack.perPcPrice = pl.price;
                                    }
                                }
                            });
                        }
                    }
                    if ((page && page === 'order-summary') && ('extraCharges' in c) && (c.extraCharges.charge > 0)) {
                        const charge = c.extraCharges.chargeAllQty ? c.extraCharges.charge : c.extraCharges.charge / c.quantity;
                        c.price += charge;
                        if ('mrpPrice' in c) {
                            c.mrpPrice += charge;
                        }
                        if ('pack' in c) {
                            c.pack.price += charge;
                        }
                    }
                    //update price according to price slabs
                    c = this.priceSlabsCheck(c, dbProduct);
                }
                else {
                    c.status = false;
                }
            }
            resolve(cartPdts);
        }));
    }
    priceSlabsCheck(cartObj, product) {
        if ('priceSlabs' in product && product.priceSlabs.active) {
            if (product.isPriceList) {
                const variantSlabs = product.priceSlabs.variantSlabs || {};
                console.log('variantSlabs', variantSlabs);
                if (Object.keys(variantSlabs).length) {
                    if (Object.keys(variantSlabs).includes(cartObj.pack.weight)) {
                        const slabs = variantSlabs[cartObj.pack.weight];
                        console.log('slabs', slabs);
                        if (slabs.length) {
                            let finalRange = {};
                            for (const element of slabs) {
                                console.log('element', element);
                                if ((cartObj.quantity >= element.qty[0]) && (cartObj.quantity <= element.qty[1])) {
                                    finalRange = element;
                                    break;
                                }
                            }
                            if (Object.keys(finalRange).length) {
                                if (cartObj.pack.variantType === 'pieces') {
                                    cartObj.price = finalRange.price * parseInt(cartObj.pack.weight);
                                    cartObj.pack.price = finalRange.price * parseInt(cartObj.pack.weight);
                                    cartObj.mrpPrice = finalRange.mrp;
                                }
                                else {
                                    cartObj.price = finalRange.price;
                                    cartObj.pack.price = finalRange.price;
                                    cartObj.mrpPrice = finalRange.mrp;
                                }
                            }
                        }
                    }
                }
            }
            else {
                const singleSlabs = product.priceSlabs.singleSlabs || {};
                if (Object.keys(singleSlabs).length) {
                    let finalRange = {};
                    for (const element of singleSlabs) {
                        if ((cartObj.quantity >= element.qty[0]) && (cartObj.quantity <= element.qty[1])) {
                            finalRange = element;
                            break;
                        }
                    }
                    if (Object.keys(finalRange).length) {
                        cartObj.price = finalRange.price;
                        cartObj.mrpPrice = finalRange.mrp;
                    }
                }
            }
        }
        return cartObj;
    }
    checkLimitedTimeDeal(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data.hasOwnProperty('deal') && data.deal.isAllowed) {
                let discount = data.deal.discount;
                if ('specificUsers' in data.deal && data.deal.specificUsers.active && data.deal.specificUsers.users && data.deal.specificUsers.users.length) {
                    const uid = yield this.storage.get('uid');
                    const index = data.deal.specificUsers.users.findIndex(u => u.id === uid);
                    if (index === -1) {
                        return { dealAllowed: false, discount: null };
                    }
                    else {
                        discount = data.deal.specificUsers.users[index].discount;
                    }
                }
                const currentTime = moment();
                const startDate = moment(data.deal.start.date).format('YYYY-MM-DD');
                const startTime = moment(data.deal.start.time).format('HH:mm');
                const endDate = moment(data.deal.end.date).format('YYYY-MM-DD');
                const endTime = moment(data.deal.end.time).format('HH:mm');
                const startDateTime = moment(`${startDate} ${startTime}`);
                const endDateTime = moment(`${endDate} ${endTime}`);
                if (moment(currentTime).isBetween(startDateTime, endDateTime)) {
                    return { dealAllowed: true, discount };
                }
                else {
                    return { dealAllowed: false, discount: null };
                }
            }
            else {
                return { dealAllowed: false, discount: null };
            }
        });
    }
    compareCartWithUpdatedCart(cartProducts, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const priceForRetail = this.configService.environment.priceForRetail;
            const subRole = yield this.storage.get('userSubRole');
            const products = cartProducts.filter(p => p.orderType !== 'free');
            const updatedCart = yield this.updatedCartFromBackend(products, page);
            const updateFields = ['maxQty', 'minQty', 'price', 'status', 'gst', 'shippingWt'];
            updatedCart.map(el => {
                if (el.hasOwnProperty('dealDiscount')) {
                    let actualPrice = el.mrpPrice ? el.mrpPrice : el.price;
                    el.price = actualPrice - (actualPrice * (el.dealDiscount / 100));
                    el.mrpPrice = actualPrice;
                }
                if (subRole && subRole === 'retailer' && priceForRetail) {
                    let retailDiscount = el.retailDiscount ? el.retailDiscount : 0;
                    let retailDiscountType = el.retailDiscountType || 'percentage';
                    if (retailDiscount) {
                        let actualPrice = el.mrpPrice ? el.mrpPrice : el.price;
                        if (retailDiscountType === 'percentage') {
                            el.price = actualPrice - (actualPrice * (retailDiscount / 100));
                        }
                        else {
                            el.price = actualPrice - retailDiscount;
                        }
                        el.mrpPrice = actualPrice;
                    }
                }
                el.price = this.sharedNewService.getPriceBasedOnExhangeRate(el.price);
                el.mrpPrice = this.sharedNewService.getPriceBasedOnExhangeRate(el.mrpPrice);
            });
            let cartList = [];
            let cartUpdated = false;
            products.map(cart => {
                const index = updatedCart.findIndex(uc => uc.id === cart.id);
                if (index !== -1) {
                    console.log('index present', cart.id);
                    const updatedProduct = updatedCart[index];
                    const { quantity } = updatedProduct, change = tslib_1.__rest(updatedProduct, ["quantity"]);
                    change['quantity'] = cart.quantity;
                    for (const key of Object.keys(cart)) {
                        if (!cartUpdated && updateFields.includes(key)) {
                            cartUpdated = cart[key] !== change[key];
                        }
                        cart[key] = change[key];
                    }
                    cartList.push(cart);
                }
                else {
                    console.log('index not present', cart.id);
                    cartUpdated = true;
                }
            });
            return { cartList, cartUpdated };
        });
    }
    inventoryManagement(products) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let manageInventory = firebase.functions().httpsCallable('orders-manageInventory');
                manageInventory({ products }).then((res) => {
                    resolve(res.data.status);
                });
            }));
        });
    }
    getFreeProductLimits() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const uid = yield this.storage.get('uid');
                    let allLimits = [];
                    const limitsRef = this.afs.collection('settings').doc('freeProductsLimit');
                    const limitDoc = yield this.afs.collection('settings').doc('freeProductsLimit').valueChanges().pipe(first()).toPromise();
                    if (limitDoc && limitDoc.active) {
                        const limits = yield limitsRef.collection('limits', ref => ref.where('active', '==', true))
                            .snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                        if (limits && limits.length) {
                            for (const limit of limits) {
                                const usage = yield limitsRef.collection('limits').doc(limit.id).collection('usage', ref => ref.where('userId', '==', uid)).valueChanges().pipe(first()).toPromise();
                                if (usage && usage.length < limit.perUser) {
                                    const product = yield this.afs.collection('products').doc(limit.product.id).valueChanges().pipe(first()).toPromise();
                                    if (product) {
                                        if (product.isPriceList && limit.product.type === 'variant') {
                                            if (product.priceList.length) {
                                                const variantIndex = product.priceList.findIndex(v => v.weight === limit.product.variant);
                                                if (variantIndex !== -1) {
                                                    if (product.priceList[variantIndex].totalQuantity !== '0') {
                                                        const freeProduct = this.getPriceListCartObj({ data: product, id: limit.product.id }, variantIndex);
                                                        freeProduct.price = 0;
                                                        freeProduct.pack.price = 0;
                                                        if (freeProduct.pack.variantType === 'pieces') {
                                                            freeProduct.pack.perPcPrice = 0;
                                                        }
                                                        allLimits.push(Object.assign({}, limit, { freeProduct }));
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            if (product.productQty !== '0') {
                                                const freeProduct = this.getCartObj(product, limit.product.id);
                                                freeProduct.price = 0;
                                                allLimits.push(Object.assign({}, limit, { freeProduct }));
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (allLimits.length) {
                        allLimits.sort((a, b) => {
                            return a.orderAmount < b.orderAmount ? 1 : -1;
                        });
                    }
                    resolve(allLimits);
                }
                catch (error) {
                    console.log(error);
                    resolve([]);
                }
            }));
        });
    }
}
CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.LogglyLoggerService), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.AlertController), i0.ɵɵinject(i5.LabelService), i0.ɵɵinject(i4.LoadingController), i0.ɵɵinject(i6.UtilsService), i0.ɵɵinject(i7.ConfigService), i0.ɵɵinject(i8.VendorService), i0.ɵɵinject(i9.Router), i0.ɵɵinject(i10.SharedNewService)); }, token: CartService, providedIn: "root" });
