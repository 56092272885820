import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';

@Injectable({
  providedIn: 'root'
})
export class SharedNewService {

  constructor(private configService: ConfigService,
    private multiCountriesService: MultiCountriesService) { }

  showQtyLeftMsg(product: any, index = 0) {
    const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
    let quantity = 0;
    quantity = product.isPriceList ? +product.priceList[index].totalQuantity || 0 : +product.productQty || 0; 
    return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
  }

  showPriceListQtyLeftMsg(quantity: number) {
    const qtyLeftMsgCount = this.configService.environment.qtyLeft || 5;
    return quantity > 0 && quantity <= qtyLeftMsgCount ? quantity : 0;
  }

  getPriceBasedOnExhangeRate(price) {
    if(this.multiCountriesService.multiCountriesSettings.settings.autoExchange) {
      const toRate = this.multiCountriesService.rates[this.configService.environment.currencyCode];
      const fromRate = this.multiCountriesService.rates['INR'];
      return ((toRate / fromRate) * price);
    } else {
        return price;
    }
  }
}
